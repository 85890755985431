.manifold .m-interactions__inner {
  overflow: auto;
}

.m-interactions {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  border-top: 0 !important;
}
.m-interactions__header {
  display: flex;
  justify-content: space-between;
  font-weight: 700;
  > span {
    font-weight: 400;
    color: rgba(139, 121, 101, 1);
  }
}
.m-interactions__inner {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  max-height: 250px;
  overflow: scroll;
}
.m-interaction {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 0.25rem;
  background: rgba(247, 246, 245, 1);
  border-radius: 4px;
  padding: 0.5rem 0.75rem;
  > a {
    display: none;
  }
}
.m-interaction__rank {
  font-weight: 700;
}
.m-interaction__amount {
  flex: 1 1 100%;
  gap: 0.25rem;
  display: flex !important;
  align-items: center;
}
.m-interaction__amount img {
  display: none;
}
.m-interaction__timestamp,
.m-interaction__user {
  font-size: 0.75rem;
}
.m-interaction__timestamp {
  text-align: right;
  order: 2;
  color: rgba(139, 121, 101, 1);
}
.m-interaction__floor-separator {
  display: none;
}
.m-marketplace__fab {
  display: none;
}
.m-interaction__referrer {
  display: none !important;
}
