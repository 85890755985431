.UiPill {
  @apply flex items-center justify-center rounded-full p-1 md:p-2;
  @apply bg-[var(--UiPill-bg)] text-[var(--UiPill-text)] ring-1 ring-[var(--UiPill-border)];
  @apply backdrop-blur-xl backdrop-filter;
}

.UiPill__label {
  @apply px-1 text-center first-letter:uppercase;
}

/* Avant Arte: Default */

:global(.theme-light) .UiPill,
:global([class^='theme-']) :global(.theme-light) .UiPill {
  --UiPill-bg: rgba(var(--white), 1);
  --UiPill-text: rgba(var(--shade-brown-950), 1);
  --UiPill-border: rgba(var(--shade-brown-600), 0.2);
}

/* Avant Arte: Dark */

:global(.theme-dark) .UiPill,
:global([class^='theme-']) :global(.theme-dark) .UiPill {
  --UiPill-bg: rgba(var(--shade-brown-950), 0.6);
  --UiPill-text: rgba(var(--white), 1);
  --UiPill-border: rgba(var(--shade-brown-500), 0.4);
}

/* Avant Arte: Light grey */

:global(.theme-light-grey) .UiPill,
:global([class^='theme-']) :global(.theme-light-grey) .UiPill {
  --UiPill-bg: rgba(var(--white), 1);
  --UiPill-text: rgba(var(--shade-grey-950), 1);
  --UiPill-border: rgba(var(--shade-grey-600), 0.2);
}

/* Avant Arte: Dark grey */

:global(.theme-dark-grey) .UiPill,
:global([class^='theme-']) :global(.theme-dark-grey) .UiPill {
  --UiPill-bg: rgba(var(--shade-grey-950), 0.6);
  --UiPill-text: rgba(var(--white), 1);
  --UiPill-border: rgba(var(--shade-grey-500), 0.4);
}
