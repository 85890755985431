/* ---------- Switch Icon ---------- */

.UiSwitchIcon {
  @apply flex rounded-full bg-[var(--UiSwitchIcon-bg)] shadow-[inset_0_0_0_1px_var(--UiSwitchIcon-border)] transition duration-300 ease-out;
}

@media (prefers-reduced-motion) {
  .UiSwitchIcon,
  .UiSwitchIcon__CheckIcon,
  .UiSwitchIcon__CheckIcon__icon {
    @apply transition-none;
  }
}

:global(.theme-light) .UiSwitchIcon,
:global([class^='theme-']) :global(.theme-light) .UiSwitchIcon {
  --UiSwitchIcon-bg: rgba(var(--white), 1);
  --UiSwitchIcon-border: rgba(var(--shade-brown-600), 0.2);
}

:global(.theme-dark) .UiSwitchIcon,
:global([class^='theme-']) :global(.theme-dark) .UiSwitchIcon {
  --UiSwitchIcon-bg: rgba(var(--shade-brown-950), 1);
  --UiSwitchIcon-border: rgba(var(--shade-brown-600), 0.4);
}

:global(.theme-light-grey) .UiSwitchIcon,
:global([class^='theme-']) :global(.theme-light-grey) .UiSwitchIcon {
  --UiSwitchIcon-bg: rgba(var(--white), 1);
  --UiSwitchIcon-border: rgba(var(--shade-grey-600), 0.2);
}

:global(.theme-dark-grey) .UiSwitchIcon,
:global([class^='theme-']) :global(.theme-dark-grey) .UiSwitchIcon {
  --UiSwitchIcon-bg: rgba(var(--shade-grey-950), 1);
  --UiSwitchIcon-border: rgba(var(--shade-grey-600), 0.4);
}

.UiSwitchIcon__CheckIcon {
  @apply flex items-center justify-center transition duration-300 ease-out;
  @apply border border-[var(--UiSwitchIcon-CheckIcon-border)] bg-[var(--UiSwitchIcon-CheckIcon-bg)] text-[var(--UiSwitchIcon-CheckIcon-text)];
}

.UiSwitchIcon__CheckIcon__icon {
  @apply shrink-0 transition-all duration-300 ease-out;
}

.UiSwitchIcon__CheckIcon__default,
.UiSwitchIcon__CheckIcon__checked {
  composes: UiSwitchIcon__CheckIcon;
}

:global(.theme-light) .UiSwitchIcon__CheckIcon__default,
:global([class^='theme-'])
  :global(.theme-light)
  .UiSwitchIcon__CheckIcon__default {
  --UiSwitchIcon-CheckIcon-text: rgba(var(--white), 1);
  --UiSwitchIcon-CheckIcon-bg: rgba(var(--white), 1);
  --UiSwitchIcon-CheckIcon-border: rgba(var(--shade-brown-600), 0.2);
}

:global(.theme-light) .UiSwitchIcon__CheckIcon__checked,
:global([class^='theme-'])
  :global(.theme-light)
  .UiSwitchIcon__CheckIcon__checked {
  --UiSwitchIcon-CheckIcon-text: rgba(var(--white), 1);
  --UiSwitchIcon-CheckIcon-bg: rgba(var(--shade-brown-950), 1);
  --UiSwitchIcon-CheckIcon-border: rgba(var(--shade-brown-950), 1);
}

:global(.theme-dark) .UiSwitchIcon__CheckIcon__default,
:global([class^='theme-'])
  :global(.theme-dark)
  .UiSwitchIcon__CheckIcon__default {
  --UiSwitchIcon-CheckIcon-text: rgba(var(--white), 1);
  --UiSwitchIcon-CheckIcon-bg: rgba(var(--shade-brown-950), 1);
  --UiSwitchIcon-CheckIcon-border: rgba(var(--shade-brown-600), 0.4);
}

:global(.theme-dark) .UiSwitchIcon__CheckIcon__checked,
:global([class^='theme-'])
  :global(.theme-dark)
  .UiSwitchIcon__CheckIcon__checked {
  --UiSwitchIcon-CheckIcon-text: rgba(var(--shade-brown-950), 1);
  --UiSwitchIcon-CheckIcon-bg: rgba(var(--white), 1);
  --UiSwitchIcon-CheckIcon-border: rgba(var(--white), 1);
}

:global(.theme-light-grey) .UiSwitchIcon__CheckIcon__default,
:global([class^='theme-'])
  :global(.theme-light-grey)
  .UiSwitchIcon__CheckIcon__default {
  --UiSwitchIcon-CheckIcon-text: rgba(var(--shade-grey-950), 1);
  --UiSwitchIcon-CheckIcon-bg: rgba(var(--white), 1);
  --UiSwitchIcon-CheckIcon-border: rgba(var(--shade-grey-600), 0.2);
}

:global(.theme-light-grey) .UiSwitchIcon__CheckIcon__checked,
:global([class^='theme-'])
  :global(.theme-light-grey)
  .UiSwitchIcon__CheckIcon__checked {
  --UiSwitchIcon-CheckIcon-text: rgba(var(--white), 1);
  --UiSwitchIcon-CheckIcon-bg: rgba(var(--shade-grey-950), 1);
  --UiSwitchIcon-CheckIcon-border: rgba(var(--shade-grey-950), 1);
}

:global(.theme-dark-grey) .UiSwitchIcon__CheckIcon__default,
:global([class^='theme-'])
  :global(.theme-dark-grey)
  .UiSwitchIcon__CheckIcon__default {
  --UiSwitchIcon-CheckIcon-text: rgba(var(--white), 1);
  --UiSwitchIcon-CheckIcon-bg: rgba(var(--shade-grey-950), 1);
  --UiSwitchIcon-CheckIcon-border: rgba(var(--shade-grey-600), 0.4);
}

:global(.theme-dark-grey) .UiSwitchIcon__CheckIcon__checked,
:global([class^='theme-'])
  :global(.theme-dark-grey)
  .UiSwitchIcon__CheckIcon__checked {
  --UiSwitchIcon-CheckIcon-text: rgba(var(--shade-grey-950), 1);
  --UiSwitchIcon-CheckIcon-bg: rgba(var(--white), 1);
  --UiSwitchIcon-CheckIcon-border: rgba(var(--white), 1);
}

/* ---------- Switch ---------- */

.UiSwitch {
  @apply relative flex w-full gap-3 overflow-hidden rounded-sm py-0.5 text-left;
  @apply outline outline-2 outline-offset-2 outline-transparent transition-all duration-300 ease-out;
  @apply focus-within:outline-[var(--UiSwitch-outline)];
}

@media (prefers-reduced-motion) {
  .UiSwitch {
    @apply transition-none;
  }
}

.UiSwitch__default {
  composes: UiSwitch;
}

.UiSwitch__checked {
  composes: UiSwitch;
}

.UiSwitch__input {
  @apply absolute left-0 top-0 opacity-0;
}

.UiSwitch__label {
  @apply flex w-full flex-col;
}

.UiSwitch__label__value {
  @apply w-full text-[var(--UiSwitch-text-primary)];
}

.UiSwitch__label__description {
  @apply w-full text-[var(--UiSwitch-text-secondary)];
}

.UiSwitch__suffix {
  @apply shrink-0 text-[var(--UiSwitch-text-primary)];
}

:global(.theme-light) .UiSwitch,
:global([class^='theme-']) :global(.theme-light) .UiSwitch {
  --UiSwitch-text-primary: rgba(var(--shade-brown-950), 1);
  --UiSwitch-text-secondary: rgba(var(--shade-brown-600), 1);
  --UiSwitch-outline: rgba(var(--shade-brown-500), 0.5);
}

:global(.theme-dark) .UiSwitch,
:global([class^='theme-']) :global(.theme-dark) .UiSwitch {
  --UiSwitch-text-primary: rgba(var(--white), 1);
  --UiSwitch-text-secondary: rgba(var(--shade-brown-400), 1);
  --UiSwitch-outline: rgba(var(--shade-brown-500), 0.75);
}

:global(.theme-light-grey) .UiSwitch,
:global([class^='theme-']) :global(.theme-light-grey) .UiSwitch {
  --UiSwitch-text-primary: rgba(var(--shade-grey-950), 1);
  --UiSwitch-text-secondary: rgba(var(--shade-grey-500), 1);
  --UiSwitch-outline: rgba(var(--shade-grey-400), 0.5);
}

:global(.theme-dark-grey) .UiSwitch,
:global([class^='theme-']) :global(.theme-dark-grey) .UiSwitch {
  --UiSwitch-text-primary: rgba(var(--white), 1);
  --UiSwitch-text-secondary: rgba(var(--shade-grey-400), 1);
  --UiSwitch-outline: rgba(var(--shade-grey-500), 0.75);
}

/* ---------- SwitchCard ---------- */

.UiSwitchCard {
  @apply relative flex w-full overflow-hidden rounded-sm text-left transition-all duration-300 ease-out;
  @apply border border-[var(--UiSwitchCard-border)] bg-[var(--UiSwitchCard-bg)];
  @apply outline outline-2 outline-offset-2 outline-transparent;
  @apply focus-within:outline-[var(--UiSwitchCard-outline)];
}

@media (prefers-reduced-motion) {
  .UiSwitchCard {
    @apply transition-none;
  }
}

.UiSwitchCard__default {
  composes: UiSwitchCard;
}

.UiSwitchCard__checked {
  composes: UiSwitchCard;
}

.UiSwitchCard__thumbnail {
  @apply relative w-14 shrink-0 bg-neutral-1;
}

.UiSwitchCard__input {
  @apply absolute left-0 top-0 opacity-0;
}

.UiSwitchCard__label {
  @apply flex w-full flex-col;
}

.UiSwitchCard__label__value {
  @apply w-full text-[var(--UiSwitchCard-text-primary)];
}

.UiSwitchCard__label__description {
  @apply w-full text-[var(--UiSwitchCard-text-secondary)];
}

.UiSwitchCard__suffix {
  @apply shrink-0 text-[var(--UiSwitchCard-text-primary)];
}

:global(.theme-light) .UiSwitchCard,
:global([class^='theme-']) :global(.theme-light) .UiSwitchCard {
  --UiSwitchCard-bg: rgba(var(--white), 1);
  --UiSwitchCard-text-primary: rgba(var(--shade-brown-950), 1);
  --UiSwitchCard-text-secondary: rgba(var(--shade-brown-600), 1);
  --UiSwitchCard-outline: rgba(var(--shade-brown-500), 0.5);
}

:global(.theme-dark) .UiSwitchCard,
:global([class^='theme-']) :global(.theme-dark) .UiSwitchCard {
  --UiSwitchCard-bg: rgba(var(--shade-brown-950), 1);
  --UiSwitchCard-text-primary: rgba(var(--white), 1);
  --UiSwitchCard-text-secondary: rgba(var(--shade-brown-400), 1);
  --UiSwitchCard-outline: rgba(var(--shade-brown-500), 0.75);
}

:global(.theme-light-grey) .UiSwitchCard,
:global([class^='theme-']) :global(.theme-light-grey) .UiSwitchCard {
  --UiSwitchCard-bg: rgba(var(--white), 1);
  --UiSwitchCard-text-primary: rgba(var(--shade-grey-950), 1);
  --UiSwitchCard-text-secondary: rgba(var(--shade-grey-500), 1);
  --UiSwitchCard-outline: rgba(var(--shade-grey-400), 0.5);
}

:global(.theme-dark-grey) .UiSwitchCard,
:global([class^='theme-']) :global(.theme-dark-grey) .UiSwitchCard {
  --UiSwitchCard-bg: rgba(var(--shade-grey-950), 1);
  --UiSwitchCard-text-primary: rgba(var(--white), 1);
  --UiSwitchCard-text-secondary: rgba(var(--shade-grey-400), 1);
  --UiSwitchCard-outline: rgba(var(--shade-grey-500), 0.75);
}

:global(.theme-light) .UiSwitchCard__default,
:global([class^='theme-']) :global(.theme-light) .UiSwitchCard__default {
  --UiSwitchCard-border: rgba(var(--shade-brown-600), 0.2);
}

:global(.theme-light) .UiSwitchCard__checked,
:global([class^='theme-']) :global(.theme-light) .UiSwitchCard__checked {
  --UiSwitchCard-border: rgba(var(--shade-brown-600), 0.2);
}

:global(.theme-dark) .UiSwitchCard__default,
:global([class^='theme-']) :global(.theme-dark) .UiSwitchCard__default {
  --UiSwitchCard-border: rgba(var(--shade-brown-600), 0.4);
}

:global(.theme-dark) .UiSwitchCard__checked,
:global([class^='theme-']) :global(.theme-dark) .UiSwitchCard__checked {
  --UiSwitchCard-border: rgba(var(--shade-brown-600), 0.4);
}

:global(.theme-light-grey) .UiSwitchCard__default,
:global([class^='theme-']) :global(.theme-light-grey) .UiSwitchCard__default {
  --UiSwitchCard-border: rgba(var(--shade-grey-600), 0.2);
}

:global(.theme-light-grey) .UiSwitchCard__checked,
:global([class^='theme-']) :global(.theme-light-grey) .UiSwitchCard__checked {
  --UiSwitchCard-border: rgba(var(--shade-grey-600), 0.2);
}

:global(.theme-dark-grey) .UiSwitchCard__default,
:global([class^='theme-']) :global(.theme-dark-grey) .UiSwitchCard__default {
  --UiSwitchCard-border: rgba(var(--shade-grey-600), 0.4);
}

:global(.theme-dark-grey) .UiSwitchCard__checked,
:global([class^='theme-']) :global(.theme-dark-grey) .UiSwitchCard__checked {
  --UiSwitchCard-border: rgba(var(--shade-grey-600), 0.4);
}
