@import './manifold/interactions.css';
@import './manifold/price.css';
@import './manifold/connection.css';
@import './manifold/bid-form.css';
@import './manifold/countdown.css';

.manifold {
  color: rgba(29, 22, 17, 1);
  --manifold-text--font-family--body: var(--font-avant-arte), sans-serif;
  --manifold-text--font-family--header: var(--font-avant-arte), sans-serif;
  font-size: 0.875rem;
  @media (min-width: 768px) {
    font-size: 1rem;
  }
  overflow: visible !important;
}

.manifold * {
  overflow: visible;
}

.manifold *:focus {
  border-color: rgb(139 121 101);
  outline: solid 2px hsla(32, 16%, 47%, 0.2);
}

.product-tile {
  .manifold {
    .m-price {
      justify-content: left;
    }

    .m-price-label {
      display: none;
    }

    .m-price-offers {
      display: none;
    }
  }
  .floor-price {
    .manifold {
      .m-price {
        .m-price-offers {
          display: block;
        }
        .m-price-label,
        .m-price-data {
          display: none;
        }
        .m-price-offer-floor {
          .m-price-data {
            display: block;
          }
        }
      }
    }
  }
}
