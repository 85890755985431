button.UiButton,
a.UiButton {
  @apply pointer-events-auto font-sans tabular-nums antialiased;
  @apply relative flex items-center justify-center outline outline-2 outline-offset-2 outline-transparent transition-all duration-300 ease-out;

  background-color: var(--UiButton-bg);
  color: var(--UiButton-text);
  border: solid 1px var(--UiButton-border);
}

@media (prefers-reduced-motion) {
  button.UiButton,
  a.UiButton {
    transition-duration: 0ms;
  }
}

/* Interactions */

button.UiButton:not(:disabled):hover,
a.UiButton:not(:disabled):hover {
  @apply opacity-90;
}

button.UiButton:not(:disabled):focus,
a.UiButton:not(:disabled):focus {
  outline-color: var(--UiButton-outline);
}

button.UiButton:not(:disabled):active,
a.UiButton:not(:disabled):active {
  @apply scale-95;
}

/* Variants */

.UiButton__variant__primary,
.UiButton__variant__secondary,
.UiButton__variant__tertiary,
.UiButton__variant__transparent,
.UiButton__variant__positive,
.UiButton__variant__danger,
.UiButton__variant__info {
  composes: UiButton;
}

/* Avant Arte: Default */

:global(.theme-light) .UiButton__variant__primary,
:global([class^='theme-']) :global(.theme-light) .UiButton__variant__primary {
  --UiButton-bg: rgba(var(--shade-brown-950), 1);
  --UiButton-border: rgba(var(--shade-brown-950), 1);
  --UiButton-text: rgba(var(--white), 1);
  --UiButton-outline: rgba(var(--shade-brown-600), 0.5);
}

:global(.theme-light) .UiButton__variant__secondary,
:global([class^='theme-']) :global(.theme-light) .UiButton__variant__secondary {
  --UiButton-bg: rgba(var(--shade-brown-100), 1);
  --UiButton-border: rgba(var(--shade-brown-100), 1);
  --UiButton-text: rgba(var(--shade-brown-950), 1);
  --UiButton-outline: rgba(var(--shade-brown-600), 0.5);
}

:global(.theme-light) .UiButton__variant__tertiary,
:global([class^='theme-']) :global(.theme-light) .UiButton__variant__tertiary {
  --UiButton-bg: rgba(var(--white), 1);
  --UiButton-border: rgba(var(--shade-brown-600), 0.2);
  --UiButton-text: rgba(var(--shade-brown-950), 1);
  --UiButton-outline: rgba(var(--shade-brown-600), 0.5);
}

:global(.theme-light) .UiButton__variant__transparent,
:global([class^='theme-'])
  :global(.theme-light)
  .UiButton__variant__transparent {
  --UiButton-bg: transparent;
  --UiButton-border: transparent;
  --UiButton-text: rgba(var(--shade-brown-950), 1);
  --UiButton-outline: rgba(var(--shade-brown-600), 0.5);
}

:global(.theme-light) .UiButton__variant__positive,
:global([class^='theme-']) :global(.theme-light) .UiButton__variant__positive {
  --UiButton-bg: rgba(var(--shade-green-100), 1);
  --UiButton-border: rgba(var(--shade-green-100), 1);
  --UiButton-text: rgba(var(--shade-green-600), 1);
  --UiButton-outline: rgba(var(--shade-green-500), 0.5);
}

:global(.theme-light) .UiButton__variant__danger,
:global([class^='theme-']) :global(.theme-light) .UiButton__variant__danger {
  --UiButton-bg: rgba(var(--shade-red-100), 1);
  --UiButton-border: rgba(var(--shade-red-100), 1);
  --UiButton-text: rgba(var(--shade-red-600), 1);
  --UiButton-outline: rgba(var(--shade-red-500), 0.5);
}

:global(.theme-light) .UiButton__variant__info,
:global([class^='theme-']) :global(.theme-light) .UiButton__variant__info {
  --UiButton-bg: transparent;
  --UiButton-border: transparent;
  --UiButton-text: rgba(var(--shade-blue-600), 1);
  --UiButton-outline: rgba(var(--shade-blue-500), 0.5);
}

/* Avant Arte: Dark */

:global(.theme-dark) .UiButton__variant__primary,
:global([class^='theme-']) :global(.theme-dark) .UiButton__variant__primary {
  --UiButton-bg: rgba(var(--white), 1);
  --UiButton-border: rgba(var(--white), 1);
  --UiButton-text: rgba(var(--shade-brown-950), 1);
  --UiButton-outline: rgba(var(--shade-brown-500), 0.75);
}

:global(.theme-dark) .UiButton__variant__secondary,
:global([class^='theme-']) :global(.theme-dark) .UiButton__variant__secondary {
  --UiButton-bg: rgba(var(--shade-brown-900), 1);
  --UiButton-border: rgba(var(--shade-brown-900), 1);
  --UiButton-text: rgba(var(--white), 1);
  --UiButton-outline: rgba(var(--shade-brown-500), 0.75);
}

:global(.theme-dark) .UiButton__variant__tertiary,
:global([class^='theme-']) :global(.theme-dark) .UiButton__variant__tertiary {
  --UiButton-bg: rgba(var(--shade-brown-950), 1);
  --UiButton-border: rgba(var(--shade-brown-600), 0.4);
  --UiButton-text: rgba(var(--white), 1);
  --UiButton-outline: rgba(var(--shade-brown-500), 0.75);
}

:global(.theme-dark) .UiButton__variant__transparent,
:global([class^='theme-'])
  :global(.theme-dark)
  .UiButton__variant__transparent {
  --UiButton-bg: transparent;
  --UiButton-border: transparent;
  --UiButton-text: rgba(var(--white), 1);
  --UiButton-outline: rgba(var(--shade-brown-500), 0.75);
}

:global(.theme-dark) .UiButton__variant__positive,
:global([class^='theme-']) :global(.theme-dark) .UiButton__variant__positive {
  --UiButton-bg: rgba(var(--shade-green-900), 1);
  --UiButton-border: rgba(var(--shade-green-900), 1);
  --UiButton-text: rgba(var(--shade-green-300), 1);
  --UiButton-outline: rgba(var(--shade-green-500), 0.75);
}

:global(.theme-dark) .UiButton__variant__danger,
:global([class^='theme-']) :global(.theme-dark) .UiButton__variant__danger {
  --UiButton-bg: rgba(var(--shade-red-900), 1);
  --UiButton-border: rgba(var(--shade-red-900), 1);
  --UiButton-text: rgba(var(--shade-red-200), 1);
  --UiButton-outline: rgba(var(--shade-red-500), 0.75);
}

:global(.theme-dark) .UiButton__variant__info,
:global([class^='theme-']) :global(.theme-dark) .UiButton__variant__info {
  --UiButton-bg: transparent;
  --UiButton-border: transparent;
  --UiButton-text: rgba(var(--shade-blue-200), 1);
  --UiButton-outline: rgba(var(--shade-blue-500), 0.75);
}

/* Avant Arte: Light grey */

:global(.theme-light-grey) .UiButton__variant__primary,
:global([class^='theme-'])
  :global(.theme-light-grey)
  .UiButton__variant__primary {
  --UiButton-bg: rgba(var(--shade-grey-950), 1);
  --UiButton-border: rgba(var(--shade-grey-950), 1);
  --UiButton-text: rgba(var(--white), 1);
  --UiButton-outline: rgba(var(--shade-grey-500), 0.5);
}

:global(.theme-light-grey) .UiButton__variant__secondary,
:global([class^='theme-'])
  :global(.theme-light-grey)
  .UiButton__variant__secondary {
  --UiButton-bg: rgba(var(--shade-grey-100), 1);
  --UiButton-border: rgba(var(--shade-grey-100), 1);
  --UiButton-text: rgba(var(--shade-grey-950), 1);
  --UiButton-outline: rgba(var(--shade-grey-500), 0.5);
}

:global(.theme-light-grey) .UiButton__variant__tertiary,
:global([class^='theme-'])
  :global(.theme-light-grey)
  .UiButton__variant__tertiary {
  --UiButton-bg: rgba(var(--white), 1);
  --UiButton-border: rgba(var(--shade-grey-600), 0.2);
  --UiButton-text: rgba(var(--shade-grey-950), 1);
  --UiButton-outline: rgba(var(--shade-grey-500), 0.5);
}

:global(.theme-light-grey) .UiButton__variant__transparent,
:global([class^='theme-'])
  :global(.theme-light-grey)
  .UiButton__variant__transparent {
  --UiButton-bg: transparent;
  --UiButton-border: transparent;
  --UiButton-text: rgba(var(--shade-grey-950), 1);
  --UiButton-outline: rgba(var(--shade-grey-500), 0.5);
}

:global(.theme-light-grey) .UiButton__variant__positive,
:global([class^='theme-'])
  :global(.theme-light-grey)
  .UiButton__variant__positive {
  --UiButton-bg: rgba(var(--shade-green-100), 1);
  --UiButton-border: rgba(var(--shade-green-100), 1);
  --UiButton-text: rgba(var(--shade-green-600), 1);
  --UiButton-outline: rgba(var(--shade-green-500), 0.5);
}

:global(.theme-light-grey) .UiButton__variant__danger,
:global([class^='theme-'])
  :global(.theme-light-grey)
  .UiButton__variant__danger {
  --UiButton-bg: rgba(var(--shade-red-100), 1);
  --UiButton-border: rgba(var(--shade-red-100), 1);
  --UiButton-text: rgba(var(--shade-red-600), 1);
  --UiButton-outline: rgba(var(--shade-red-500), 0.5);
}

:global(.theme-light-grey) .UiButton__variant__info,
:global([class^='theme-']) :global(.theme-light-grey) .UiButton__variant__info {
  --UiButton-bg: transparent;
  --UiButton-border: transparent;
  --UiButton-text: rgba(var(--shade-blue-600), 1);
  --UiButton-outline: rgba(var(--shade-blue-500), 0.5);
}

/* Avant Arte: Dark grey */

:global(.theme-dark-grey) .UiButton__variant__primary,
:global([class^='theme-'])
  :global(.theme-dark-grey)
  .UiButton__variant__primary {
  --UiButton-bg: rgba(var(--white), 1);
  --UiButton-border: rgba(var(--white), 1);
  --UiButton-text: rgba(var(--shade-grey-950), 1);
  --UiButton-outline: rgba(var(--shade-grey-500), 0.75);
}

:global(.theme-dark-grey) .UiButton__variant__secondary,
:global([class^='theme-'])
  :global(.theme-dark-grey)
  .UiButton__variant__secondary {
  --UiButton-bg: rgba(var(--shade-grey-900), 1);
  --UiButton-border: rgba(var(--shade-grey-900), 1);
  --UiButton-text: rgba(var(--white), 1);
  --UiButton-outline: rgba(var(--shade-grey-500), 0.75);
}

:global(.theme-dark-grey) .UiButton__variant__tertiary,
:global([class^='theme-'])
  :global(.theme-dark-grey)
  .UiButton__variant__tertiary {
  --UiButton-bg: rgba(var(--shade-grey-950), 1);
  --UiButton-border: rgba(var(--shade-grey-600), 0.4);
  --UiButton-text: rgba(var(--white), 1);
  --UiButton-outline: rgba(var(--shade-grey-500), 0.75);
}

:global(.theme-dark-grey) .UiButton__variant__transparent,
:global([class^='theme-'])
  :global(.theme-dark-grey)
  .UiButton__variant__transparent {
  --UiButton-bg: transparent;
  --UiButton-border: transparent;
  --UiButton-text: rgba(var(--white), 1);
  --UiButton-outline: rgba(var(--shade-grey-500), 0.75);
}

:global(.theme-dark-grey) .UiButton__variant__positive,
:global([class^='theme-'])
  :global(.theme-dark-grey)
  .UiButton__variant__positive {
  --UiButton-bg: rgba(var(--shade-green-900), 1);
  --UiButton-border: rgba(var(--shade-green-900), 1);
  --UiButton-text: rgba(var(--shade-green-300), 1);
  --UiButton-outline: rgba(var(--shade-green-500), 0.75);
}

:global(.theme-dark-grey) .UiButton__variant__danger,
:global([class^='theme-'])
  :global(.theme-dark-grey)
  .UiButton__variant__danger {
  --UiButton-bg: rgba(var(--shade-red-900), 1);
  --UiButton-border: rgba(var(--shade-red-900), 1);
  --UiButton-text: rgba(var(--shade-red-200), 1);
  --UiButton-outline: rgba(var(--shade-red-500), 0.75);
}

:global(.theme-dark-grey) .UiButton__variant__info,
:global([class^='theme-']) :global(.theme-dark-grey) .UiButton__variant__info {
  --UiButton-bg: transparent;
  --UiButton-border: transparent;
  --UiButton-text: rgba(var(--shade-blue-200), 1);
  --UiButton-outline: rgba(var(--shade-blue-500), 0.75);
}
