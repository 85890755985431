/* ----- CAROUSEL ----- */

.UiCarousel {
  @apply relative w-full overflow-x-clip px-[var(--UiCarousel-padding)];

  --UiCarousel-item: 300px;
  --UiCarousel-padding: calc(
    50% - (var(--website-max-width) / 2) + var(--website-paddings)
  );
}

.UiCarousel__arrows__top {
  @apply flex items-center justify-center space-x-3;
}

.UiCarousel__arrows__middle {
  @apply absolute inset-0 flex h-full w-full items-center justify-between px-4;
}

.UiCarousel__arrows__top > button:disabled,
.UiCarousel__arrows__middle > button:disabled {
  @apply cursor-not-allowed opacity-50;
}

.UiCarousel__track {
  @apply w-full !overflow-visible;
}

.UiCarousel__track__slide {
  @apply !min-w-[var(--UiCarousel-item)];
}

@media (min-width: 640px) {
  .UiCarousel {
    --UiCarousel-item: 448px;
  }
}

@media (min-width: 768px) {
  .UiCarousel {
    --UiCarousel-item: auto;
  }
}

/* ----- GRID which becomes a CAROUSEL on mobile ----- */

.UiGridCarousel {
  composes: UiCarousel;
}

.UiGridCarousel__track {
  composes: UiCarousel__track;
}

.UiGridCarousel__track > ul {
  @apply md:!grid md:!grid-cols-12 md:!gap-x-5 md:!gap-y-6;
}

.UiGridCarousel__track__slide {
  composes: UiCarousel__track__slide;
}

/* ----- Product gallery ----- */

.UiProductImageGallery {
  @apply w-full;
}

.UiProductImageGallery__track {
  @apply w-full overflow-hidden no-scrollbar sm:rounded-sm;
}

.UiProductImageGallery__track > ul {
  @apply md:!grid md:!grid-cols-2 md:!gap-3;
}

.UiProductImageGallery__track__slide {
  @apply md:overflow-hidden md:rounded-sm;
}

.UiProductImageGallery__track__slide:first-child {
  @apply md:!col-span-full;
}

.UiProductImageGallery__track__slide:not(:first-child) {
  @apply md:!col-span-full lg:!col-span-1;
}

/* ----- Artist About ----- */

.UiArtistImageGallery {
  @apply relative w-full overflow-x-clip px-[var(--UiArtistImageGallery-padding)];

  --UiArtistImageGallery-item: 448px;
  --UiArtistImageGallery-padding: calc(
    50% - (var(--website-max-width) / 2) + var(--website-paddings)
  );
}

.UiArtistImageGallery__arrows {
  @apply absolute inset-0 flex h-full w-full items-center justify-between px-[var(--UiArtistImageGallery-padding)];
}

.UiArtistImageGallery__arrows > button:disabled {
  @apply cursor-not-allowed opacity-50;
}

.UiArtistImageGallery__track {
  @apply w-full !overflow-visible;
}

.UiArtistImageGallery__track__slide {
  @apply w-full min-w-[var(--UiCarousel-item)];
}

@media (min-width: 640px) {
  .UiArtistImageGallery {
    --UiArtistImageGallery-item: 448px;
  }
}

@media (min-width: 768px) {
  .UiArtistImageGallery {
    --UiArtistImageGallery-item: 640px;
    --UiArtistImageGallery-padding: calc(
      50% - (var(--UiArtistImageGallery-item) / 2) + var(--website-paddings)
    );
  }
}

@media (min-width: 1024px) {
  .UiArtistImageGallery {
    --UiArtistImageGallery-item: 768px;
  }
}

@media (min-width: 1280px) {
  .UiArtistImageGallery {
    --UiArtistImageGallery-item: 768px;
  }
}

@media (min-width: 1536px) {
  .UiArtistImageGallery {
    --UiArtistImageGallery-item: 768px;
  }
}

/* ----- Private Room ----- */

.privateRoomGallery__wrapper {
  @apply relative flex w-full flex-col overflow-x-clip px-[var(--UiPrivateRoomGallery-padding)];

  --UiPrivateRoomGallery-width: var(--website-max-width);
  --UiPrivateRoomGallery-item: 300px;
  --UiPrivateRoomGallery-padding: calc(
    50% - (var(--UiPrivateRoomGallery-width) / 2) + var(--website-paddings)
  );
}

@media (min-width: 672px) {
  .privateRoomGallery__wrapper {
    --UiPrivateRoomGallery-width: 576px;
    --UiPrivateRoomGallery-item: auto;
  }
}

@media (min-width: 896px) {
  .privateRoomGallery__wrapper {
    --UiPrivateRoomGallery-width: 896px;
    --UiPrivateRoomGallery-item: auto;
  }
}

.privateRoomGallery {
}

.privateRoomGallery__arrows {
  @apply absolute inset-0 flex h-full w-full items-center justify-between px-[var(--UiPrivateRoomGallery-padding)];
}

.privateRoomGallery__arrows > button:disabled {
  @apply invisible opacity-0;
}

.privateRoomGallery__carousel__track {
  @apply w-full !overflow-visible;
}

.privateRoomGallery__carousel__track__slide {
  @apply w-full !min-w-[var(--UiPrivateRoomGallery-item)] md:max-w-4xl;
}

.privateRoomGallery__grid__track > ul {
  @apply !grid w-full !grid-cols-2 !gap-5 !overflow-visible px-website;
  @apply !translate-x-0;
}
