.m-countdown {
  text-align: center;
}
.m-countdown-title {
  display: none;
}
.m-countdown-clock {
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 0.25rem;
  > div {
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: wrap-reverse;
    justify-content: center;
    gap: 0.25rem;
  }
}
