.UiSkeleton {
  @apply block animate-pulse bg-[var(--UiSkeleton-bg)];
}

/* Avant Arte: Default */

:global(.theme-light) .UiSkeleton,
:global([class^='theme-']) :global(.theme-light) .UiSkeleton {
  --UiSkeleton-bg: rgba(var(--shade-brown-600), 0.2);
}

/* Avant Arte: Dark */

:global(.theme-dark) .UiSkeleton,
:global([class^='theme-']) :global(.theme-dark) .UiSkeleton {
  --UiSkeleton-bg: rgba(var(--shade-brown-500), 0.4);
}

/* Avant Arte: Light grey */

:global(.theme-light-grey) .UiSkeleton,
:global([class^='theme-']) :global(.theme-light-grey) .UiSkeleton {
  --UiSkeleton-bg: rgba(var(--shade-grey-600), 0.2);
}

/* Avant Arte: Dark grey */

:global(.theme-dark-grey) .UiSkeleton,
:global([class^='theme-']) :global(.theme-dark-grey) .UiSkeleton {
  --UiSkeleton-bg: rgba(var(--shade-grey-500), 0.4);
}
