/* ---------- UiBannerCard ---------- */

.UiBannerCard__icon {
  @apply shrink-0 rounded-full border border-[var(--UiBannerCard-icon-border)] bg-[var(--UiBannerCard-icon-bg)] p-4 text-[var(--UiBannerCard-icon-text)];
}

:global(.theme-light) .UiBannerCard__icon,
:global([class^='theme-']) :global(.theme-light) .UiBannerCard__icon {
  --UiBannerCard-icon-bg: rgba(var(--white), 1);
  --UiBannerCard-icon-border: rgba(var(--shade-brown-600), 0.2);
  --UiBannerCard-icon-text: rgba(var(--shade-brown-950), 1);
}
:global(.theme-dark) .UiBannerCard__icon,
:global([class^='theme-']) :global(.theme-dark) .UiBannerCard__icon {
  --UiBannerCard-icon-bg: rgba(var(--shade-brown-950), 1);
  --UiBannerCard-icon-border: rgba(var(--shade-brown-600), 0.4);
  --UiBannerCard-icon-text: rgba(var(--white), 1);
}
:global(.theme-light-grey) .UiBannerCard__icon,
:global([class^='theme-']) :global(.theme-light-grey) .UiBannerCard__icon {
  --UiBannerCard-icon-bg: rgba(var(--white), 1);
  --UiBannerCard-icon-border: rgba(var(--shade-grey-600), 0.2);
  --UiBannerCard-icon-text: rgba(var(--shade-grey-950), 1);
}
:global(.theme-dark-grey) .UiBannerCard__icon,
:global([class^='theme-']) :global(.theme-dark-grey) .UiBannerCard__icon {
  --UiBannerCard-icon-bg: rgba(var(--shade-grey-950), 1);
  --UiBannerCard-icon-border: rgba(var(--shade-grey-600), 0.4);
  --UiBannerCard-icon-text: rgba(var(--white), 1);
}

/* ---------- UiMenuCard ---------- */

.UiMenuCard__icon {
  @apply flex h-10 w-10 shrink-0 items-center justify-center rounded-full bg-[var(--UiMenuCard-icon-bg)] text-[var(--UiMenuCard-icon-text)];
}

:global(.theme-light) .UiMenuCard__icon,
:global([class^='theme-']) :global(.theme-light) .UiMenuCard__icon {
  --UiMenuCard-icon-bg: rgba(var(--shade-brown-100), 1);
  --UiMenuCard-icon-text: rgba(var(--shade-brown-950), 1);
}
:global(.theme-dark) .UiMenuCard__icon,
:global([class^='theme-']) :global(.theme-dark) .UiMenuCard__icon {
  --UiMenuCard-icon-bg: rgba(var(--shade-brown-900), 1);
  --UiMenuCard-icon-text: rgba(var(--white), 1);
}
:global(.theme-light-grey) .UiMenuCard__icon,
:global([class^='theme-']) :global(.theme-light-grey) .UiMenuCard__icon {
  --UiMenuCard-icon-bg: rgba(var(--shade-grey-200), 1);
  --UiMenuCard-icon-text: rgba(var(--shade-grey-950), 1);
}
:global(.theme-dark-grey) .UiMenuCard__icon,
:global([class^='theme-']) :global(.theme-dark-grey) .UiMenuCard__icon {
  --UiMenuCard-icon-bg: rgba(var(--shade-grey-800), 1);
  --UiMenuCard-icon-text: rgba(var(--white), 1);
}
