@import './font-face.css';
@import './variables.css';

/* ----- VARIABLES ----- */

:root {
  --website-max-width: 100%; /* To match Tailwind CSS */
  --website-paddings: 20px; /* To match Tailwind CSS */
  --carousel-border-width: 20px; /* To match Tailwind CSS */

  --website-absolute-padding: calc(
    50% - (var(--website-max-width) / 2) + var(--website-paddings)
  );
}

@media (min-width: 480px) {
  :root {
    --website-max-width: 480px; /* To match Tailwind CSS */
  }
}
@media (min-width: 640px) {
  :root {
    --website-max-width: 640px; /* To match Tailwind CSS */
  }
}

@media (min-width: 768px) {
  :root {
    --website-max-width: 768px; /* To match Tailwind CSS */
  }
}
@media (min-width: 1024px) {
  :root {
    --website-max-width: 1024px; /* To match Tailwind CSS */
  }
}
@media (min-width: 1280px) {
  :root {
    --website-max-width: 1280px; /* To match Tailwind CSS */
  }
}
@media (min-width: 1536px) {
  :root {
    --website-max-width: 1536px; /* To match Tailwind CSS */
  }
}

html {
  min-height: 100%;
  position: relative;
}

html,
body {
  @apply w-full;
}

body {
  @apply w-full bg-white font-sans !text-sm-mobile tabular-nums antialiased theme-dark:bg-neutral-6 theme-dark-grey:bg-black md:!text-sm;
  /*fix android highlight when clicking elements*/
  -webkit-tap-highlight-color: transparent;
}

body.block-scroll {
  @apply h-screen w-full overflow-hidden;
}

body.bg-image {
  @apply bg-cover bg-center bg-no-repeat;
}

.text-balance {
  text-wrap: balance;
}

.wrapper-website {
  /* to-do: Make sure this is used as a wrapper */
  @apply max-w-120 sm:max-w-160 md:max-w-3xl lg:max-w-5xl xl:max-w-7xl 2xl:max-w-website; /* To match breakpoints */
  @apply relative mx-auto w-full px-website;
}

.grid-section {
  @apply grid grid-cols-12 gap-4 md:gap-5;
  /* @apply max-w-website m-auto; this shouldn't be there if the section is wrapped around the wrapper-website */
}

button,
input,
select,
textarea {
  @apply tabular-nums;
}

input[type='date']::-webkit-calendar-picker-indicator {
  width: 1.25rem;
  height: 1.25rem;
  margin: -0.125rem 0 0 0;
  padding: 0;
  cursor: pointer;
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCIgZmlsbD0ibm9uZSI+PHBhdGggZmlsbD0iIzFEMTYxMSIgZD0iTTQuNzU3IDE3LjU4M3EtLjU2NCAwLS45NTItLjM5NWExLjMgMS4zIDAgMCAxLS4zODgtLjk0NFY1Ljc1NnEwLS41NDguMzg4LS45NDQuMzg5LS4zOTUuOTUyLS4zOTVoMS44MlYyLjMyaDEuMTE1djIuMDk2aDQuNjQ4VjIuMzJoMS4wODN2Mi4wOTZoMS44MnEuNTY0IDAgLjk1Mi4zOTVhMS4zIDEuMyAwIDAgMSAuMzg4Ljk0NHYxMC40ODhxMCAuNTQ4LS4zODguOTQ0LS4zOS4zOTUtLjk1MS4zOTV6bTAtMS4wODNoMTAuNDg3YS4yNS4yNSAwIDAgMCAuMTc2LS4wOC4yNS4yNSAwIDAgMCAuMDgtLjE3N1Y5LjI1N2gtMTF2Ni45ODhxMCAuMDk2LjA4LjE3NnQuMTc3LjA4Ii8+PC9zdmc+');
}

/* 
  Fieldset & Legend Reset https://gist.github.com/lucywheel/908001a7a6793a3f9a77df9221eb4466
*/
legend {
  @apply float-left m-0 table w-full p-0;
}
legend + * {
  @apply clear-both;
}

fieldset {
  @apply m-0 min-w-0 border-none p-0;
}

body:not(:-moz-handler-blocked) fieldset {
  display: table-cell;
}

/*
  Used a couple of different strategies here with some custom logic too. See:
  - src/hooks/useDisableBodyScrolling.tsx
  - https://pqina.nl/blog/how-to-prevent-scrolling-the-page-on-ios-safari/
  - https://amazon.com/
 */

.scroll-lock {
  height: calc(var(--window-inner-height) - 1px);
  box-sizing: border-box;
}

.scroll-lock body {
  position: fixed;
  overflow: hidden;
}

.pageLocked {
  overflow: hidden;
}

/* ----- */

.search * {
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
}

/* ----- */

.link {
  @apply cursor-pointer text-inherit transition duration-300 ease-out;
  @apply hover:text-neutral-4;
  @apply theme-dark:hover:text-neutral-3;
  @apply theme-light-grey:hover:text-neutral-4;
  @apply theme-dark-grey:hover:text-neutral-3;
}

.hyperlink,
.aa-prose a {
  @apply cursor-pointer text-inherit underline decoration-inherit decoration-solid underline-offset-[.25em] transition-colors duration-300 ease-out hover:decoration-transparent;
  @apply hover:text-neutral-4;
  @apply theme-dark:hover:text-neutral-3;
  @apply theme-light-grey:hover:text-neutral-4;
  @apply theme-dark-grey:hover:text-neutral-3;
}

.glossary-link {
  @apply cursor-pointer text-inherit underline decoration-inherit decoration-dashed underline-offset-4 transition-colors duration-300 ease-out;
  @apply hover:text-neutral-4;
  @apply theme-dark:hover:text-neutral-3;
  @apply theme-light-grey:hover:text-neutral-4;
  @apply theme-dark-grey:hover:text-neutral-3;
}

/* ----- */

.aa-prose {
  @apply mx-auto w-full !max-w-lg;
  @apply prose prose-sm md:prose-md;
  @apply prose-headings:text-md prose-headings:font-bold;
  @apply prose-strong:font-bold;
  /* @apply prose-a:border-b prose-a:no-underline hover:prose-a:border-none; */
  @apply prose-blockquote:pl-8 prose-blockquote:not-italic;
  @apply prose-ul:list-[dash];
  @apply prose-p:my-0;
}

.aa-prose p + p {
  @apply mt-4;
}

.aa-prose b {
  @apply font-bold;
}

.rich-text-content p:last-child {
  @apply pb-0;
}

.rich-text-content p {
  @apply pb-4;
}

.rich-text-content ul {
  @apply list-inside;
  @apply list-disc;
}

.rich-text-content ul p {
  @apply inline-table;
  @apply w-11/12;
}

.rich-text-list li {
  @apply p-6;
}

.rich-text-list li::before {
  @apply absolute;
  @apply left-4;
  @apply md:left-11;
  @apply -mt-0.5;
  content: '■';
}

.rich-text-list p {
  @apply w-11/12;
  @apply pb-4;
}

.rich-text-list p b,
.rich-text-content b {
  @apply font-bold;
}

.rich-text-content i {
  @apply italic;
}

.slick-track {
  @apply float-left;
}

.quote::before {
  @apply absolute;
  @apply -ml-2;
}

.product-variant-icon {
  @apply relative;
}

.product-variant-icon::after {
  @apply absolute;
  @apply pointer-events-none;
  @apply left-0;
  @apply top-0;
  @apply w-full;
  @apply h-full;
  @apply content-[''];
  @apply border-2;
  @apply border-transparent;
  @apply transition-all;
  @apply duration-300;
}

.product-variant-icon:hover::after {
  @apply border-neutral-6/10;
}

.product-variant-icon\:active::after,
.product-variant-icon\:active:hover::after {
  @apply border-neutral-6/30;
}

.hidden-image {
  height: 0px !important;
  width: 0px !important;
}

/* create our own pulse since jit must be disabled on tailwind to use its pulse */
@keyframes pulse {
  from {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }

  to {
    opacity: 1;
  }
}

.aa-animate-pulse {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  -webkit-animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  -moz-animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  -o-animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

.vimeoPlayer {
  padding: 100% 0 0 0;
  position: relative;
}

.vimeoPlayer iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/*******************************************************************************
  Cookie Consent + Hubspot Chat
  =========================

  This CSS ensures that the Hubspot chat button plays nicely with the cookie banner
  at the bottom of the page.

  When the cookie banner is visible:
    the Hubspot chat button is shifted up to appear above it.

  When the cookie banner is hidden:
    the Hubspot chat button appears normally.

  The Hubspot chat button animates in sync with the cookie banner.

  Selectors
  =========

  .react-live-chat-hubspot-wrapper > iframe
    The hubspot iframe which functions as a button


*******************************************************************************/

/* Hide the cookie banner by default */
.cookie-banner-container {
  @apply fixed bottom-0 left-0;
  @apply w-full;
  @apply z-50;
  @apply transition-transform duration-300;
  @apply translate-y-full;
}

/* Show the cookie banner when it should be visible */
body.cookie-banner-visible .cookie-banner-container {
  @apply translate-y-0;
}

/* Keep the hubspot chat button in it's default position by default */
div#hubspot-messages-iframe-container {
  @apply transition-transform duration-300 !important;
  @apply translate-y-0 !important;
}

/* Shift the hubspot chat button up when the cookie banner is visible */
body.cookie-banner-visible div#hubspot-messages-iframe-container {
  @apply -translate-y-32 md:-translate-y-24 !important;
}

/* Shift the hubspot chat window down when expanded open on mobile, if /*
/* the cookie banner is visible */
body.cookie-banner-visible
  div#hubspot-messages-iframe-container
  .active
  + iframe {
  @apply translate-y-32 md:translate-y-24 !important;
}

/* Shift the hubspot chat window up when the cookie banner is visible, */
/* but not when in mobile mode. */
@media (min-width: 451px) {
  body.cookie-banner-visible div#hubspot-messages-iframe-container {
    @apply max-h-[70vh];
    @apply -translate-y-32 !important;
  }

  body.cookie-banner-visible
    div#hubspot-messages-iframe-container
    .active
    + iframe {
    @apply translate-y-0 !important;
  }
}

@media (min-width: 768px) {
  body.cookie-banner-visible div#hubspot-messages-iframe-container {
    @apply max-h-[70vh];
    @apply -translate-y-24 !important;
  }
}

/* Move lightboxes above hubspot chat buttons and drawers */
body div#hubspot-messages-iframe-container {
  z-index: 2147483000 !important;
}

.fslightbox-open .fslightbox-container {
  z-index: 2147483203;
}

/* reCAPTCHA - Global */
[style*='z-index: 2000000000'] {
  z-index: 3000000000 !important;
}

/* reCAPTCHA - Desktop */
body > div[style*='z-index: 2000000000'] {
  margin-top: var(--window-scroll-height) !important;
}

/* reCAPTCHA - Mobile */
@media (max-width: 767px) {
  body
    > div[style*='right: 0px; transition: visibility 0s linear 0s, opacity 0.3s linear 0s; opacity: 1;'] {
    top: 0px !important;
  }
}

.checkbox {
  min-height: 36px !important;
}

@media (min-width: 768px) {
  .checkbox {
    min-height: 42px !important;
  }
}

/* src/components/LinkCards/LinkCard.tsx */
.link-card {
  position: relative;
  overflow: hidden;
  display: grid;
  gap: 12px;

  grid-template-columns: 1fr min-content;
  grid-template-rows: 1fr min-content;

  /* Hack for overflowing videos on Safari */
  isolation: isolate;
}

.link-card:not(.cta) {
  cursor: pointer;
}

.link-card.media {
  grid-template-columns: 1fr;
  grid-template-rows: 1fr min-content min-content;
}

.link-card p:last-of-type {
  padding-bottom: 0;
}

@media (min-width: 768px) {
  .link-card {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr min-content min-content;
  }
}

.link-card-drop-shadow-filter {
  filter: drop-shadow(0px 1px 20px rgba(43, 32, 17, 0.6));
}

.group-hero-banner img {
  @apply !scale-100; /* important */
}

.shadow-border-box {
  position: relative;
  z-index: 0;
}

.shadow-border-box::before {
  @apply shadow-border;
  border-radius: inherit;
  content: '';
  display: block;
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 1;
}

.scroll-px-20 {
  scroll-padding-left: 1.25rem;
  scroll-padding-right: 1.25rem;
}

/* src/components/GoogleOAuth.tsx */
.img-spinner {
  animation: rotate 2s linear infinite;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

.inputArea {
  grid-template-areas: 'checkbox';
}

.inputArea span,
.inputArea input {
  grid-area: checkbox;
}

/* Pad final slide so it can be centered when scrolling */

.private-slide--outro:after {
  @apply relative left-[100%] block h-px content-[''];
  width: calc((100vw - 512px) / 2); /* max-w-lg */
}

@keyframes private-sales-product-details-open {
  from {
    transform: translateY(10%);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 100%;
  }
}

.private-link-product:hover .privatelink-list-card-image {
  color: rgba(255, 255, 255, 0.7);
}

.private-link-product:hover .privatelink-list-price {
  color: rgba(255, 255, 255, 0.7);
}

.private-link-product:hover .privatelink-list-title {
  color: rgba(255, 255, 255, 0.7);
}

.modal-cover {
  @apply fixed bottom-0 left-0 right-0 top-0 z-[519] bg-neutral-6/70;
}

.theme-dark-grey .modal-cover {
  @apply bg-black/80;
}

.modal-cover > *,
.modal-cover > .model-cover-children {
  @apply fixed bottom-0 left-0 right-0 top-0 z-[520] flex flex-col;
}

.modal-cover:focus,
.modal-cover > *:focus {
  @apply outline-none;
}

.dialog-container {
  max-height: 100%;
}

.dialog-container-desktop {
  max-height: calc(100% - 24vmin);
}

.addresses-alert {
  animation:
    alertMobile 1.5s,
    fadeOut 3s forwards;

  -moz-animation:
    alertMobile 1.5s,
    fadeOut 3s forwards;
  /* Firefox */
  -webkit-animation:
    alertMobile 1.5s,
    fadeOut 3s forwards;
  /* Safari and Chrome */
  -o-animation:
    alertMobile 1.5s,
    fadeOut 3s forwards;
  /* Opera */
  animation:
    alertMobile 1.5s,
    fadeOut 3s forwards;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;

  @media (min-width: 768px) {
    animation:
      alertDesktop 1.5s,
      fadeOut 3s forwards;
  }
}

@keyframes alertDesktop {
  0% {
    bottom: -100%;
  }

  100% {
    bottom: 30px;
  }
}

@-webkit-keyframes alertDesktop {
  0% {
    bottom: -100%;
  }

  100% {
    bottom: 30px;
  }
}

@keyframes alertMobile {
  0% {
    bottom: -100%;
  }

  100% {
    bottom: 24px;
  }
}

@-webkit-keyframes alertMobile {
  0% {
    bottom: -100%;
  }

  100% {
    bottom: 24px;
  }
}

@keyframes fadeOut {
  80% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@-webkit-keyframes fadeOut {
  80% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.input-search-cancel-none::-webkit-search-cancel-button {
  display: none;
}

/* styles for 'intrinsic' ratio on Image.tsx */
.image-intrinsic-container > span {
  position: unset !important;
}

.image-intrinsic {
  height: unset !important;
  object-fit: contain;
  position: relative !important;
  width: 100% !important;
}

@counter-style dash {
  system: cyclic;
  symbols: '\2014';
  suffix: ' ';
}

.react-tooltip.glossaryTooltip {
  @apply animate-fadeIn border border-divider/20 bg-white opacity-100;
  @apply z-90 w-72 rounded-sm p-3 shadow-card;
}

.react-tooltip-arrow.glossaryArrow {
  @apply border-b border-r border-divider/20;
  @apply h-4 w-4 rotate-45 rounded-br-sm;
}

.react-tooltip__place-top .react-tooltip-arrow.glossaryArrow {
  @apply -mb-1;
}

.react-tooltip__place-bottom .react-tooltip-arrow.glossaryArrow {
  @apply -mt-1;
}

.scrolly-video-container {
  @apply absolute bottom-0 left-0 right-0 -z-50 m-auto h-full w-full max-w-website sm:max-w-xl;
}

.scrolly-video-container:before {
  @apply absolute left-0 right-0 top-0 z-20 m-0 block h-160 w-full bg-gradient-to-b from-white via-white/90 p-0 content-[''];
}

.scrolly-video-container:after {
  @apply absolute bottom-0 left-0 right-0 z-20 m-auto block h-px bg-gradient-to-r from-transparent via-neutral-5/20 p-0 content-[''];
}

.fslightbox-source {
  @apply flex !important;
  @apply items-center;
  @apply justify-center;
}

.vimeo-container iframe {
  @apply max-w-[90vw];
  @apply max-h-[90vh];
}

.preserve3d {
  transform-style: preserve-3d;
}

.slider {
  height: 5px;
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  width: 100%;
}

.slider {
  --range: calc(var(--max) - var(--min));
  --ratio: calc((var(--value) - var(--min)) / var(--range));
  --filledRange: calc(0.5 * 10px + var(--ratio) * (100% - 10px));
}

.slider:focus {
  outline: none;
}

.slider::-moz-range-thumb {
  width: 18px;
  height: 18px;
  border-radius: 1em;
  background: #fff;
  border: 1px solid #dfdad3;
  margin-top: calc(6px * 0.5 - 20px * 0.5);
  margin-bottom: calc(6px * 0.5 - 20px * 0.5);
  -webkit-appearance: none;
  appearance: none;
}

.slider::-moz-range-track {
  height: 3px;
  border-radius: 0.5em;
  background: #dfdad3;
  border: none;
  box-shadow: none;
}

.slider:hover::-moz-range-track {
  background: #dfdad3;
}

.slider:active::-moz-range-track {
  background: #dfdad3;
}

.slider:active::-moz-range-track {
  background: #dfdad3;
}

.slider::-moz-range-track {
  background:
    linear-gradient(#5e4625, #5e4625) 0 / var(--filledRange) 100% no-repeat,
    #dfdad3;
}

.slider:hover::-moz-range-track {
  background:
    linear-gradient(#5e4625, #5e4625) 0 / var(--filledRange) 100% no-repeat,
    #dfdad3;
}

.slider:active::-moz-range-track {
  background:
    linear-gradient(#5e4625, #5e4625) 0 / var(--filledRange) 100% no-repeat,
    #dfdad3;
}

.slider::-webkit-slider-thumb {
  width: 18px;
  height: 18px;
  border-radius: 1em;
  background: #fff;
  border: 1px solid #dfdad3;
  margin-top: calc(6px * 0.5 - 20px * 0.5);
  margin-bottom: calc(6px * 0.5 - 20px * 0.5);
  -webkit-appearance: none;
}

.slider::-webkit-slider-runnable-track {
  height: 3px;
  border-radius: 0.5em;
  background: #dfdad3;
  border: none;
  box-shadow: none;
}

.slider:hover::-webkit-slider-runnable-track {
  background: #dfdad3;
}

.slider:active::-webkit-slider-runnable-track {
  background: #dfdad3;
}

.slider::-webkit-slider-runnable-track {
  background:
    linear-gradient(#5e4625, #5e4625) 0 / var(--filledRange) 100% no-repeat,
    #dfdad3;
}

.slider:hover::-webkit-slider-runnable-track {
  background:
    linear-gradient(#5e4625, #5e4625) 0 / var(--filledRange) 100% no-repeat,
    #dfdad3;
}

.slider:active::-webkit-slider-runnable-track {
  background:
    linear-gradient(#5e4625, #5e4625) 0 / var(--filledRange) 100% no-repeat,
    #dfdad3;
}

.form-disabled-bg {
  @apply bg-neutral-2;
}

.normal-form-bg {
  @apply bg-neutral-1;
}
.light-form-bg {
  @apply bg-white;
}

.alert-icon {
  @apply ml-4 h-6 w-6 flex-shrink-0 rounded-full md:h-8 md:w-8;
}

#veriff-submit-btn {
  --tw-bg-opacity: 1;
  background-color: rgb(29 22 17 / var(--tw-bg-opacity));
  --tw-border-opacity: 1;
  border-color: rgb(29 22 17 / var(--tw-border-opacity));
  padding: 1rem;
  height: auto;
  border-width: 1px;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
  position: relative;
  transition-duration: 300ms;
  font-size: 1rem;
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
}

p.veriff-description {
  display: none;
}

/* This would hide the placeholder while the carousel mounts to avoid layout shift */
/* which is important for SEO */

.splide.is-initialized + .splide-skeleton {
  @apply hidden;
}
