/* ---------- Check Icon ---------- */

.UiCheckicon {
  @apply flex items-center justify-center transition duration-300 ease-out;
  @apply border border-[var(--UiCheckicon-border)] bg-[var(--UiCheckicon-bg)] text-[var(--UiCheckicon-text)];
}

.UiCheckicon__icon {
  @apply shrink-0 transition-all duration-300 ease-out;
}

@media (prefers-reduced-motion) {
  .UiCheckicon,
  .UiCheckicon__icon {
    @apply transition-none;
  }
}

.UiCheckicon__default {
  composes: UiCheckicon;
}

.UiCheckicon__checked {
  composes: UiCheckicon;
}

:global(.theme-light) .UiCheckicon__default,
:global([class^='theme-']) :global(.theme-light) .UiCheckicon__default {
  --UiCheckicon-text: rgba(var(--white), 1);
  --UiCheckicon-bg: rgba(var(--white), 1);
  --UiCheckicon-border: rgba(var(--shade-brown-600), 0.2);
}

:global(.theme-light) .UiCheckicon__checked,
:global([class^='theme-']) :global(.theme-light) .UiCheckicon__checked {
  --UiCheckicon-text: rgba(var(--white), 1);
  --UiCheckicon-bg: rgba(var(--shade-brown-950), 1);
  --UiCheckicon-border: rgba(var(--shade-brown-950), 1);
}

:global(.theme-dark) .UiCheckicon__default,
:global([class^='theme-']) :global(.theme-dark) .UiCheckicon__default {
  --UiCheckicon-text: rgba(var(--white), 1);
  --UiCheckicon-bg: rgba(var(--shade-brown-950), 1);
  --UiCheckicon-border: rgba(var(--shade-brown-600), 0.4);
}

:global(.theme-dark) .UiCheckicon__checked,
:global([class^='theme-']) :global(.theme-dark) .UiCheckicon__checked {
  --UiCheckicon-text: rgba(var(--shade-brown-950), 1);
  --UiCheckicon-bg: rgba(var(--white), 1);
  --UiCheckicon-border: rgba(var(--white), 1);
}

:global(.theme-light-grey) .UiCheckicon__default,
:global([class^='theme-']) :global(.theme-light-grey) .UiCheckicon__default {
  --UiCheckicon-text: rgba(var(--shade-grey-950), 1);
  --UiCheckicon-bg: rgba(var(--white), 1);
  --UiCheckicon-border: rgba(var(--shade-grey-600), 0.2);
}

:global(.theme-light-grey) .UiCheckicon__checked,
:global([class^='theme-']) :global(.theme-light-grey) .UiCheckicon__checked {
  --UiCheckicon-text: rgba(var(--white), 1);
  --UiCheckicon-bg: rgba(var(--shade-grey-950), 1);
  --UiCheckicon-border: rgba(var(--shade-grey-950), 1);
}

:global(.theme-dark-grey) .UiCheckicon__default,
:global([class^='theme-']) :global(.theme-dark-grey) .UiCheckicon__default {
  --UiCheckicon-text: rgba(var(--white), 1);
  --UiCheckicon-bg: rgba(var(--shade-grey-950), 1);
  --UiCheckicon-border: rgba(var(--shade-grey-600), 0.4);
}

:global(.theme-dark-grey) .UiCheckicon__checked,
:global([class^='theme-']) :global(.theme-dark-grey) .UiCheckicon__checked {
  --UiCheckicon-text: rgba(var(--shade-grey-950), 1);
  --UiCheckicon-bg: rgba(var(--white), 1);
  --UiCheckicon-border: rgba(var(--white), 1);
}

/* ---------- Checkbox ---------- */

.UiCheckbox {
  @apply relative flex w-full gap-3 overflow-hidden rounded-sm py-0.5 text-left;
  @apply outline outline-2 outline-offset-2 outline-transparent transition-all duration-300 ease-out;
  @apply focus-within:outline-[var(--UiCheckbox-outline)];
}

@media (prefers-reduced-motion) {
  .UiCheckbox {
    @apply transition-none;
  }
}

.UiCheckbox__default {
  composes: UiCheckbox;
}

.UiCheckbox__checked {
  composes: UiCheckbox;
}

.UiCheckbox__input {
  @apply absolute left-0 top-0 opacity-0;
}

.UiCheckbox__label {
  @apply flex w-full flex-col;
}

.UiCheckbox__label__value {
  @apply w-full text-[var(--UiCheckbox-text-primary)];
}

.UiCheckbox__label__description {
  @apply w-full text-[var(--UiCheckbox-text-secondary)];
}

.UiCheckbox__suffix {
  @apply shrink-0 text-[var(--UiCheckbox-text-primary)];
}

:global(.theme-light) .UiCheckbox,
:global([class^='theme-']) :global(.theme-light) .UiCheckbox {
  --UiCheckbox-text-primary: rgba(var(--shade-brown-950), 1);
  --UiCheckbox-text-secondary: rgba(var(--shade-brown-600), 1);
  --UiCheckbox-outline: rgba(var(--shade-brown-500), 0.5);
}

:global(.theme-dark) .UiCheckbox,
:global([class^='theme-']) :global(.theme-dark) .UiCheckbox {
  --UiCheckbox-text-primary: rgba(var(--white), 1);
  --UiCheckbox-text-secondary: rgba(var(--shade-brown-400), 1);
  --UiCheckbox-outline: rgba(var(--shade-brown-500), 0.75);
}

:global(.theme-light-grey) .UiCheckbox,
:global([class^='theme-']) :global(.theme-light-grey) .UiCheckbox {
  --UiCheckbox-text-primary: rgba(var(--shade-grey-950), 1);
  --UiCheckbox-text-secondary: rgba(var(--shade-grey-500), 1);
  --UiCheckbox-outline: rgba(var(--shade-grey-400), 0.5);
}

:global(.theme-dark-grey) .UiCheckbox,
:global([class^='theme-']) :global(.theme-dark-grey) .UiCheckbox {
  --UiCheckbox-text-primary: rgba(var(--white), 1);
  --UiCheckbox-text-secondary: rgba(var(--shade-grey-400), 1);
  --UiCheckbox-outline: rgba(var(--shade-grey-500), 0.75);
}

/* ---------- CheckboxCard ---------- */

.UiCheckboxCard {
  @apply relative flex w-full overflow-hidden rounded-sm text-left transition-all duration-300 ease-out;
  @apply border border-[var(--UiCheckboxCard-border)];
  @apply outline outline-2 outline-offset-2 outline-transparent;
  @apply ring ring-1 ring-[var(--UiCheckboxCard-ring)];
  @apply focus-within:outline-[var(--UiCheckboxCard-outline)];
}

@media (prefers-reduced-motion) {
  .UiCheckboxCard {
    @apply transition-none;
  }
}

.UiCheckboxCard__default {
  composes: UiCheckboxCard;
}

.UiCheckboxCard__checked {
  composes: UiCheckboxCard;
}

.UiCheckboxCard__thumbnail {
  @apply relative w-14 shrink-0 bg-neutral-1;
}

.UiCheckboxCard__input {
  @apply absolute left-0 top-0 opacity-0;
}

.UiCheckboxCard__label {
  @apply flex w-full flex-col;
}

.UiCheckboxCard__label__value {
  @apply w-full text-[var(--UiCheckboxCard-text-primary)];
}

.UiCheckboxCard__label__description {
  @apply w-full text-[var(--UiCheckboxCard-text-secondary)];
}

.UiCheckboxCard__suffix {
  @apply shrink-0 text-[var(--UiCheckboxCard-text-primary)];
}

:global(.theme-light) .UiCheckboxCard,
:global([class^='theme-']) :global(.theme-light) .UiCheckboxCard {
  --UiCheckboxCard-text-primary: rgba(var(--shade-brown-950), 1);
  --UiCheckboxCard-text-secondary: rgba(var(--shade-brown-600), 1);
  --UiCheckboxCard-outline: rgba(var(--shade-brown-500), 0.5);
}

:global(.theme-dark) .UiCheckboxCard,
:global([class^='theme-']) :global(.theme-dark) .UiCheckboxCard {
  --UiCheckboxCard-text-primary: rgba(var(--white), 1);
  --UiCheckboxCard-text-secondary: rgba(var(--shade-brown-400), 1);
  --UiCheckboxCard-outline: rgba(var(--shade-brown-500), 0.75);
}

:global(.theme-light-grey) .UiCheckboxCard,
:global([class^='theme-']) :global(.theme-light-grey) .UiCheckboxCard {
  --UiCheckboxCard-text-primary: rgba(var(--shade-grey-950), 1);
  --UiCheckboxCard-text-secondary: rgba(var(--shade-grey-500), 1);
  --UiCheckboxCard-outline: rgba(var(--shade-grey-400), 0.5);
}

:global(.theme-dark-grey) .UiCheckboxCard,
:global([class^='theme-']) :global(.theme-dark-grey) .UiCheckboxCard {
  --UiCheckboxCard-text-primary: rgba(var(--white), 1);
  --UiCheckboxCard-text-secondary: rgba(var(--shade-grey-400), 1);
  --UiCheckboxCard-outline: rgba(var(--shade-grey-500), 0.75);
}

:global(.theme-light) .UiCheckboxCard__default,
:global([class^='theme-']) :global(.theme-light) .UiCheckboxCard__default {
  --UiCheckboxCard-border: rgba(var(--shade-brown-600), 0.2);
  --UiCheckboxCard-ring: transparent;
}

:global(.theme-light) .UiCheckboxCard__checked,
:global([class^='theme-']) :global(.theme-light) .UiCheckboxCard__checked {
  --UiCheckboxCard-border: rgba(var(--shade-brown-950), 1);
  --UiCheckboxCard-ring: rgba(var(--shade-brown-950), 1);
}

:global(.theme-dark) .UiCheckboxCard__default,
:global([class^='theme-']) :global(.theme-dark) .UiCheckboxCard__default {
  --UiCheckboxCard-border: rgba(var(--shade-brown-600), 0.4);
  --UiCheckboxCard-ring: transparent;
}

:global(.theme-dark) .UiCheckboxCard__checked,
:global([class^='theme-']) :global(.theme-dark) .UiCheckboxCard__checked {
  --UiCheckboxCard-border: rgba(var(--white), 1);
  --UiCheckboxCard-ring: rgba(var(--white), 1);
}

:global(.theme-light-grey) .UiCheckboxCard__default,
:global([class^='theme-']) :global(.theme-light-grey) .UiCheckboxCard__default {
  --UiCheckboxCard-border: rgba(var(--shade-grey-600), 0.2);
  --UiCheckboxCard-ring: transparent;
}

:global(.theme-light-grey) .UiCheckboxCard__checked,
:global([class^='theme-']) :global(.theme-light-grey) .UiCheckboxCard__checked {
  --UiCheckboxCard-border: rgba(var(--shade-grey-950), 1);
  --UiCheckboxCard-ring: rgba(var(--shade-grey-950), 1);
}

:global(.theme-dark-grey) .UiCheckboxCard__default,
:global([class^='theme-']) :global(.theme-dark-grey) .UiCheckboxCard__default {
  --UiCheckboxCard-border: rgba(var(--shade-grey-600), 0.4);
  --UiCheckboxCard-ring: transparent;
}

:global(.theme-dark-grey) .UiCheckboxCard__checked,
:global([class^='theme-']) :global(.theme-dark-grey) .UiCheckboxCard__checked {
  --UiCheckboxCard-border: rgba(var(--white), 1);
  --UiCheckboxCard-ring: rgba(var(--white), 1);
}

/* ---------- CheckboxButton ---------- */

.UiCheckboxButton {
  @apply relative flex overflow-hidden rounded-full text-left text-center transition-all duration-300 ease-out;
  @apply border border-[var(--UiCheckboxButton-border)] bg-[var(--UiCheckboxButton-bg)];
  @apply outline outline-2 outline-offset-2 outline-transparent;
  @apply ring ring-1 ring-[var(--UiCheckboxButton-ring)];
  @apply focus-within:outline-[var(--UiCheckboxButton-outline)];
}

@media (prefers-reduced-motion) {
  .UiCheckboxButton {
    @apply transition-none;
  }
}

.UiCheckboxButton__default {
  composes: UiCheckboxButton;
}

.UiCheckboxButton__checked {
  composes: UiCheckboxButton;
}

.UiCheckboxButton__input {
  @apply absolute left-0 top-0 opacity-0;
}

.UiCheckboxButton__label {
  @apply w-full min-w-5 px-1 text-[var(--UiCheckboxButton-text)];
}

:global(.theme-light) .UiCheckboxButton,
:global([class^='theme-']) :global(.theme-light) .UiCheckboxButton {
  --UiCheckboxButton-bg: rgba(var(--white), 1);
  --UiCheckboxButton-text: rgba(var(--shade-brown-950), 1);
  --UiCheckboxButton-outline: rgba(var(--shade-brown-500), 0.5);
}

:global(.theme-dark) .UiCheckboxButton,
:global([class^='theme-']) :global(.theme-dark) .UiCheckboxButton {
  --UiCheckboxButton-bg: rgba(var(--shade-brown-950), 1);
  --UiCheckboxButton-text: rgba(var(--white), 1);
  --UiCheckboxButton-outline: rgba(var(--shade-brown-500), 0.75);
}

:global(.theme-light-grey) .UiCheckboxButton,
:global([class^='theme-']) :global(.theme-light-grey) .UiCheckboxButton {
  --UiCheckboxButton-bg: rgba(var(--white), 1);
  --UiCheckboxButton-text: rgba(var(--shade-grey-950), 1);
  --UiCheckboxButton-outline: rgba(var(--shade-grey-400), 0.5);
}

:global(.theme-dark-grey) .UiCheckboxButton,
:global([class^='theme-']) :global(.theme-dark-grey) .UiCheckboxButton {
  --UiCheckboxButton-bg: rgba(var(--shade-grey-950), 1);
  --UiCheckboxButton-text: rgba(var(--white), 1);
  --UiCheckboxButton-outline: rgba(var(--shade-grey-500), 0.75);
}

:global(.theme-light) .UiCheckboxButton__default,
:global([class^='theme-']) :global(.theme-light) .UiCheckboxButton__default {
  --UiCheckboxButton-border: rgba(var(--shade-brown-600), 0.2);
  --UiCheckboxButton-ring: transparent;
}

:global(.theme-light) .UiCheckboxButton__checked,
:global([class^='theme-']) :global(.theme-light) .UiCheckboxButton__checked {
  --UiCheckboxButton-border: rgba(var(--shade-brown-950), 1);
  --UiCheckboxButton-ring: rgba(var(--shade-brown-950), 1);
}

:global(.theme-dark) .UiCheckboxButton__default,
:global([class^='theme-']) :global(.theme-dark) .UiCheckboxButton__default {
  --UiCheckboxButton-border: rgba(var(--shade-brown-600), 0.4);
  --UiCheckboxButton-ring: transparent;
}

:global(.theme-dark) .UiCheckboxButton__checked,
:global([class^='theme-']) :global(.theme-dark) .UiCheckboxButton__checked {
  --UiCheckboxButton-border: rgba(var(--white), 1);
  --UiCheckboxButton-ring: rgba(var(--white), 1);
}

:global(.theme-light-grey) .UiCheckboxButton__default,
:global([class^='theme-'])
  :global(.theme-light-grey)
  .UiCheckboxButton__default {
  --UiCheckboxButton-border: rgba(var(--shade-grey-600), 0.2);
  --UiCheckboxButton-ring: transparent;
}

:global(.theme-light-grey) .UiCheckboxButton__checked,
:global([class^='theme-'])
  :global(.theme-light-grey)
  .UiCheckboxButton__checked {
  --UiCheckboxButton-border: rgba(var(--shade-grey-950), 1);
  --UiCheckboxButton-ring: rgba(var(--shade-grey-950), 1);
}

:global(.theme-dark-grey) .UiCheckboxButton__default,
:global([class^='theme-'])
  :global(.theme-dark-grey)
  .UiCheckboxButton__default {
  --UiCheckboxButton-border: rgba(var(--shade-grey-600), 0.4);
  --UiCheckboxButton-ring: transparent;
}

:global(.theme-dark-grey) .UiCheckboxButton__checked,
:global([class^='theme-'])
  :global(.theme-dark-grey)
  .UiCheckboxButton__checked {
  --UiCheckboxButton-border: rgba(var(--white), 1);
  --UiCheckboxButton-ring: rgba(var(--white), 1);
}

/* ---------- CheckboxImage ---------- */

.UiCheckboxImage {
  @apply relative flex aspect-[1/1] w-full overflow-hidden rounded-sm transition-all duration-300 ease-out;
  @apply border border-[var(--UiCheckboxImage-border)];
  @apply outline outline-2 outline-offset-2 outline-transparent;
  @apply ring ring-1 ring-[var(--UiCheckboxImage-ring)];
  @apply focus-within:outline-[var(--UiCheckboxImage-outline)];
}

@media (prefers-reduced-motion) {
  .UiCheckboxImage {
    @apply transition-none;
  }
}

.UiCheckboxImage__default {
  composes: UiCheckboxImage;
}

.UiCheckboxImage__checked {
  composes: UiCheckboxImage;
}

.UiCheckboxImage__thumbnail {
  @apply relative w-14 shrink-0 bg-neutral-1;
}

.UiCheckboxImage__input {
  @apply absolute left-0 top-0 opacity-0;
}

:global(.theme-light) .UiCheckboxImage,
:global([class^='theme-']) :global(.theme-light) .UiCheckboxImage {
  --UiCheckboxImage-outline: rgba(var(--shade-brown-500), 0.5);
}

:global(.theme-dark) .UiCheckboxImage,
:global([class^='theme-']) :global(.theme-dark) .UiCheckboxImage {
  --UiCheckboxImage-outline: rgba(var(--shade-brown-500), 0.75);
}

:global(.theme-light-grey) .UiCheckboxImage,
:global([class^='theme-']) :global(.theme-light-grey) .UiCheckboxImage {
  --UiCheckboxImage-outline: rgba(var(--shade-grey-400), 0.5);
}

:global(.theme-dark-grey) .UiCheckboxImage,
:global([class^='theme-']) :global(.theme-dark-grey) .UiCheckboxImage {
  --UiCheckboxImage-outline: rgba(var(--shade-grey-500), 0.75);
}

:global(.theme-light) .UiCheckboxImage__default,
:global([class^='theme-']) :global(.theme-light) .UiCheckboxImage__default {
  --UiCheckboxImage-border: rgba(var(--shade-brown-600), 0.2);
  --UiCheckboxImage-ring: transparent;
}

:global(.theme-light) .UiCheckboxImage__checked,
:global([class^='theme-']) :global(.theme-light) .UiCheckboxImage__checked {
  --UiCheckboxImage-border: rgba(var(--shade-brown-950), 1);
  --UiCheckboxImage-ring: rgba(var(--shade-brown-950), 1);
}

:global(.theme-dark) .UiCheckboxImage__default,
:global([class^='theme-']) :global(.theme-dark) .UiCheckboxImage__default {
  --UiCheckboxImage-border: rgba(var(--shade-brown-600), 0.4);
  --UiCheckboxImage-ring: transparent;
}

:global(.theme-dark) .UiCheckboxImage__checked,
:global([class^='theme-']) :global(.theme-dark) .UiCheckboxImage__checked {
  --UiCheckboxImage-border: rgba(var(--white), 1);
  --UiCheckboxImage-ring: rgba(var(--white), 1);
}

:global(.theme-light-grey) .UiCheckboxImage__default,
:global([class^='theme-'])
  :global(.theme-light-grey)
  .UiCheckboxImage__default {
  --UiCheckboxImage-border: rgba(var(--shade-grey-600), 0.2);
  --UiCheckboxImage-ring: transparent;
}

:global(.theme-light-grey) .UiCheckboxImage__checked,
:global([class^='theme-'])
  :global(.theme-light-grey)
  .UiCheckboxImage__checked {
  --UiCheckboxImage-border: rgba(var(--shade-grey-950), 1);
  --UiCheckboxImage-ring: rgba(var(--shade-grey-950), 1);
}

:global(.theme-dark-grey) .UiCheckboxImage__default,
:global([class^='theme-']) :global(.theme-dark-grey) .UiCheckboxImage__default {
  --UiCheckboxImage-border: rgba(var(--shade-grey-600), 0.4);
  --UiCheckboxImage-ring: transparent;
}

:global(.theme-dark-grey) .UiCheckboxImage__checked,
:global([class^='theme-']) :global(.theme-dark-grey) .UiCheckboxImage__checked {
  --UiCheckboxImage-border: rgba(var(--white), 1);
  --UiCheckboxImage-ring: rgba(var(--white), 1);
}
