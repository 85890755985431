.m-bid-form {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  margin-bottom: 0.75rem;
}
.m-bid-form__status {
  display: none;
}
.m-bid-form__input-container *:focus-within {
  border-color: rgb(139 121 101);
  outline: solid 2px hsla(32, 16%, 47%, 0.2);
}

.m-bid-form__input {
  display: flex;
  width: 100%;
  border-radius: 4px;
  gap: 0.75rem;
  border: 1px solid rgba(139, 121, 101, 0.2);
  padding: 0.75rem;
  justify-content: space-between;
  align-items: center;
  > span {
    overflow: visible;
    color: rgba(139, 121, 101, 1);
  }
  > input {
    width: 100%;
  }
  > input:focus {
    border: 0;
    outline: 0;
  }
}
.m-bid-form__wallet-balance {
  display: none;
}
.m-bid-form__input > input::placeholder {
  color: rgba(139, 121, 101, 1);
}
.m-bid-form:has(.error) {
  .m-bid-form__input {
    border-color: rgba(255, 103, 103, 1);
  }
}

.m-interactive-button {
  color: white;
  background: rgb(29 22 17 / 1);
  padding: 1rem;
  border-radius: 4px;
  text-align: center;
  display: flex;
  justify-content: center;
}
.m-interactive-button:hover {
  background: rgb(29 22 17 / 1);
}
.m-interactive-button:has(.spinner) {
  padding: 0;
}
.m-interactive-button.error {
  pointer-events: none;
  opacity: 0.3;
  background: rgba(29, 22, 17, 1);
}
.m-interactive-button.success {
  background: rgba(11, 144, 92, 1);
}
.m-interactive-button.button-loading {
  > span:first-child {
    display: none;
  }
}
.manifold :where(button):disabled {
  filter: none;
  opacity: 0.3;
  background: rgba(29, 22, 17, 1);
}
.manifold :where(button):hover {
  filter: none;
}
