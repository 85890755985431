.UiGrid {
  --UiGrid-gap: 1.25rem;

  --UiGrid-cols-4: calc(100% / 4 - ((var(--UiGrid-gap) * 3) / 4));
  --UiGrid-cols-3: calc(100% / 3 - ((var(--UiGrid-gap) * 2) / 3));
  --UiGrid-cols-2: calc(100% / 2 - ((var(--UiGrid-gap) * 1) / 2));

  @apply flex flex-wrap items-start justify-center gap-[var(--UiGrid-gap)];
}

.UiGrid__4,
.UiGrid__3,
.UiGrid__2,
.UiGrid__1 {
  composes: UiGrid;
}

.UiGrid__4 > * {
  @apply w-full sm:w-[var(--UiGrid-cols-2)] lg:w-[var(--UiGrid-cols-3)] xl:w-[var(--UiGrid-cols-4)];
}

.UiGrid__3 > * {
  @apply w-full sm:w-[var(--UiGrid-cols-2)] lg:w-[var(--UiGrid-cols-3)];
}

.UiGrid__2 > * {
  @apply w-full sm:w-[var(--UiGrid-cols-2)];
}

.UiGrid__1 > * {
  @apply w-full;
}
