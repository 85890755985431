.UiDivider {
  @apply w-full border border-none;
}

.UiDivider__dashed,
.UiDivider__dotted,
.UiDivider__solid {
  composes: UiDivider;
}

.UiDivider__dashed {
  height: 1px;
  background-position: 0 0;
  background-repeat: repeat-x;
  background-size: 8px 1px;
  background-image: linear-gradient(
    to right,
    var(--UiDivider-border) 50%,
    transparent 50%
  );
}

.UiDivider__dotted {
  height: 2px;
  background-position: -2px -2px;
  background-repeat: repeat-x;
  background-size: 6px 6px;
  background-image: radial-gradient(
    ellipse,
    var(--UiDivider-border) 0px,
    var(--UiDivider-border) 1px,
    transparent 1px
  );
}

.UiDivider__solid {
  height: 1px;
  background-color: var(--UiDivider-border);
}

/* Avant Arte: Default */

:global(.theme-light) .UiDivider,
:global([class^='theme-']) :global(.theme-light) .UiDivider {
  --UiDivider-border: rgba(var(--shade-brown-600), 0.2);
}

/* Avant Arte: Dark */

:global(.theme-dark) .UiDivider,
:global([class^='theme-']) :global(.theme-dark) .UiDivider {
  --UiDivider-border: rgba(var(--shade-brown-500), 0.4);
}

/* Avant Arte: Light grey */

:global(.theme-light-grey) .UiDivider,
:global([class^='theme-']) :global(.theme-light-grey) .UiDivider {
  --UiDivider-border: rgba(var(--shade-grey-600), 0.2);
}

/* Avant Arte: Dark grey */

:global(.theme-dark-grey) .UiDivider,
:global([class^='theme-']) :global(.theme-dark-grey) .UiDivider {
  --UiDivider-border: rgba(var(--shade-grey-500), 0.4);
}
