.m-connection-disconnect-wallet,
.m-connection-switch-network {
  width: 100%;
  text-align: center;
  background: transparent;
  color: rgba(29, 22, 17, 1);
  transition-property: color;
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  text-decoration: underline;
}
.m-connection-disconnect-wallet:hover,
.m-connection-switch-network:hover {
  color: rgb(139 121 101 / 1);
  text-decoration: none;
}
.m-connection-wallet-options {
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  gap: 0.75rem;
}
.m-connection-wallet-option {
  border-radius: 4px;
  font-size: 0.75rem !important;
  text-align: center;
  width: 100%;
  justify-content: center;
  background: transparent;
  color: black;
  align-items: center;
  flex-direction: column;
  display: flex;
  padding: 0.75rem;
  gap: 0.5rem;
  border: 1px solid rgba(139, 121, 101, 0.2);
}
.m-connection-wallet-logo {
  max-width: 30px;
}
