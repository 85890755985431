.UiBadge {
  @apply flex items-center justify-center rounded-sm bg-[var(--UiBadge-bg)] p-1 text-[var(--UiBadge-text)] backdrop-blur-xl backdrop-filter md:p-2;
}

.UiBadge__label {
  @apply px-1 text-center first-letter:uppercase;
}

/* Avant Arte: Default */

:global(.theme-light) .UiBadge,
:global([class^='theme-']) :global(.theme-light) .UiBadge {
  --UiBadge-bg: rgba(var(--shade-brown-950), 0.6);
  --UiBadge-text: rgba(var(--white), 1);
}

/* Avant Arte: Dark */

:global(.theme-dark) .UiBadge,
:global([class^='theme-']) :global(.theme-dark) .UiBadge {
  --UiBadge-bg: rgba(var(--shade-brown-950), 0.6);
  --UiBadge-text: rgba(var(--white), 1);
}

/* Avant Arte: Light grey */

:global(.theme-light-grey) .UiBadge,
:global([class^='theme-']) :global(.theme-light-grey) .UiBadge {
  --UiBadge-bg: rgba(var(--shade-grey-950), 0.6);
  --UiBadge-text: rgba(var(--white), 1);
}

/* Avant Arte: Dark grey */

:global(.theme-dark-grey) .UiBadge,
:global([class^='theme-']) :global(.theme-dark-grey) .UiBadge {
  --UiBadge-bg: rgba(var(--shade-grey-950), 0.6);
  --UiBadge-text: rgba(var(--white), 1);
}
