:root {
  --white: 255, 255, 255;
  --black: 0, 0, 0;

  /* Shades */

  --shade-grey-50: 250, 250, 250;
  --shade-grey-100: 245, 245, 245;
  --shade-grey-200: 229, 229, 229;
  --shade-grey-300: 212, 212, 212;
  --shade-grey-400: 163, 163, 163;
  --shade-grey-500: 115, 115, 115;
  --shade-grey-600: 82, 82, 82;
  --shade-grey-700: 64, 64, 64;
  --shade-grey-800: 38, 38, 38;
  --shade-grey-900: 23, 23, 23;
  --shade-grey-950: 10, 10, 10;

  --shade-brown-50: 247, 246, 245;
  --shade-brown-100: 237, 235, 231;
  --shade-brown-200: 219, 215, 205;
  --shade-brown-300: 195, 189, 174;
  --shade-brown-400: 174, 163, 143;
  --shade-brown-500: 152, 137, 115;
  --shade-brown-600: 139, 121, 101;
  --shade-brown-700: 116, 99, 83;
  --shade-brown-800: 92, 78, 66;
  --shade-brown-900: 68, 57, 49;
  --shade-brown-950: 29, 22, 17;

  --shade-blue-50: 239, 245, 255;
  --shade-blue-100: 219, 232, 254;
  --shade-blue-200: 191, 215, 254;
  --shade-blue-300: 147, 187, 253;
  --shade-blue-400: 96, 154, 250;
  --shade-blue-500: 59, 130, 246;
  --shade-blue-600: 37, 112, 235;
  --shade-blue-700: 29, 100, 216;
  --shade-blue-800: 30, 85, 175;
  --shade-blue-900: 30, 71, 138;
  --shade-blue-950: 23, 46, 84;

  --shade-green-50: 237, 252, 244;
  --shade-green-100: 211, 248, 226;
  --shade-green-200: 170, 240, 202;
  --shade-green-300: 115, 226, 173;
  --shade-green-400: 59, 204, 139;
  --shade-green-500: 23, 178, 114;
  --shade-green-600: 11, 144, 92;
  --shade-green-700: 9, 115, 76;
  --shade-green-800: 10, 92, 62;
  --shade-green-900: 9, 75, 52;
  --shade-green-950: 4, 42, 30;

  --shade-orange-50: 255, 245, 237;
  --shade-orange-100: 255, 232, 212;
  --shade-orange-200: 255, 206, 168;
  --shade-orange-300: 255, 171, 112;
  --shade-orange-400: 255, 124, 55;
  --shade-orange-500: 255, 82, 7;
  --shade-orange-600: 240, 61, 6;
  --shade-orange-700: 199, 42, 7;
  --shade-orange-800: 158, 35, 14;
  --shade-orange-900: 127, 32, 15;
  --shade-orange-950: 69, 12, 5;

  --shade-red-50: 255, 241, 241;
  --shade-red-100: 255, 224, 224;
  --shade-red-200: 255, 198, 198;
  --shade-red-300: 255, 158, 158;
  --shade-red-400: 255, 103, 103;
  --shade-red-500: 252, 55, 55;
  --shade-red-600: 234, 26, 26;
  --shade-red-700: 197, 16, 16;
  --shade-red-800: 163, 17, 17;
  --shade-red-900: 134, 22, 22;
  --shade-red-950: 73, 6, 6;
}
