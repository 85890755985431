.UiAlert {
  @apply flex w-full gap-2 rounded-sm bg-[var(--UiAlert-bg)] p-3 text-[var(--UiAlert-text)];
}

.UiAlert__icon {
  @apply text-[var(--UiAlert-icon)];
}

.UiAlert__info,
.UiAlert__positive,
.UiAlert__danger {
  composes: UiAlert;
}

/* Avant Arte: Default */

:global(.theme-light) .UiAlert__info,
:global([class^='theme-']) :global(.theme-light) .UiAlert__info {
  --UiAlert-bg: rgba(var(--shade-brown-100), 1);
  --UiAlert-text: rgba(var(--shade-brown-950), 1);
  --UiAlert-icon: rgba(var(--shade-brown-600), 1);
}

:global(.theme-light) .UiAlert__positive,
:global([class^='theme-']) :global(.theme-light) .UiAlert__positive {
  --UiAlert-bg: rgba(var(--shade-green-100), 1);
  --UiAlert-text: rgba(var(--shade-green-950), 1);
  --UiAlert-icon: rgba(var(--shade-green-600), 1);
}

:global(.theme-light) .UiAlert__danger,
:global([class^='theme-']) :global(.theme-light) .UiAlert__danger {
  --UiAlert-bg: rgba(var(--shade-red-100), 1);
  --UiAlert-text: rgba(var(--shade-red-950), 1);
  --UiAlert-icon: rgba(var(--shade-red-600), 1);
}

/* Avant Arte: Dark */

:global(.theme-dark) .UiAlert__info,
:global([class^='theme-']) :global(.theme-dark) .UiAlert__info {
  --UiAlert-bg: rgba(var(--shade-brown-900), 1);
  --UiAlert-text: rgba(var(--white), 1);
  --UiAlert-icon: rgba(var(--shade-brown-400), 1);
}

:global(.theme-dark) .UiAlert__positive,
:global([class^='theme-']) :global(.theme-dark) .UiAlert__positive {
  --UiAlert-bg: rgba(var(--shade-green-950), 1);
  --UiAlert-text: rgba(var(--shade-green-50), 1);
  --UiAlert-icon: rgba(var(--shade-green-400), 1);
}

:global(.theme-dark) .UiAlert__danger,
:global([class^='theme-']) :global(.theme-dark) .UiAlert__danger {
  --UiAlert-bg: rgba(var(--shade-red-950), 1);
  --UiAlert-text: rgba(var(--shade-red-50), 1);
  --UiAlert-icon: rgba(var(--shade-red-400), 1);
}

/* Avant Arte: Light grey */

:global(.theme-light-grey) .UiAlert__info,
:global([class^='theme-']) :global(.theme-light-grey) .UiAlert__info {
  --UiAlert-bg: rgba(var(--shade-grey-200), 1);
  --UiAlert-text: rgba(var(--shade-grey-950), 1);
  --UiAlert-icon: rgba(var(--shade-grey-600), 1);
}

:global(.theme-light-grey) .UiAlert__positive,
:global([class^='theme-']) :global(.theme-light-grey) .UiAlert__positive {
  --UiAlert-bg: rgba(var(--shade-green-100), 1);
  --UiAlert-text: rgba(var(--shade-green-950), 1);
  --UiAlert-icon: rgba(var(--shade-green-600), 1);
}

:global(.theme-light-grey) .UiAlert__danger,
:global([class^='theme-']) :global(.theme-light-grey) .UiAlert__danger {
  --UiAlert-bg: rgba(var(--shade-red-100), 1);
  --UiAlert-text: rgba(var(--shade-red-950), 1);
  --UiAlert-icon: rgba(var(--shade-red-600), 1);
}

/* Avant Arte: Dark grey */

:global(.theme-dark-grey) .UiAlert__info,
:global([class^='theme-']) :global(.theme-dark-grey) .UiAlert__info {
  --UiAlert-bg: rgba(var(--shade-grey-800), 1);
  --UiAlert-text: rgba(var(--white), 1);
  --UiAlert-icon: rgba(var(--shade-grey-400), 1);
}

:global(.theme-dark-grey) .UiAlert__positive,
:global([class^='theme-']) :global(.theme-dark-grey) .UiAlert__positive {
  --UiAlert-bg: rgba(var(--shade-green-950), 1);
  --UiAlert-text: rgba(var(--shade-green-50), 1);
  --UiAlert-icon: rgba(var(--shade-green-400), 1);
}

:global(.theme-dark-grey) .UiAlert__danger,
:global([class^='theme-']) :global(.theme-dark-grey) .UiAlert__danger {
  --UiAlert-bg: rgba(var(--shade-red-950), 1);
  --UiAlert-text: rgba(var(--shade-red-50), 1);
  --UiAlert-icon: rgba(var(--shade-red-400), 1);
}
