@font-face {
  font-family: 'Rima Avant Arte';
  src: url('/fonts/RimaAvant-Arte-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Rima Avant Arte';
  src: url('/fonts/RimaAvant-Arte-RegularItalic.woff2') format('woff2');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Rima Avant Arte';
  src: url('/fonts/RimaAvant-Arte-Bold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Rima Avant Arte';
  src: url('/fonts/RimaAvant-Arte-BoldItalic.woff2') format('woff2');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

:root {
  --font-avant-arte: 'Rima Avant Arte';
}
