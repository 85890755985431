.UiCard {
  @apply relative h-full w-full overflow-hidden rounded-sm bg-[var(--UiCard-bg)] text-left text-[var(--UiCard-text)] ring-1 ring-[var(--UiCard-border)];
}

@media (prefers-reduced-motion) {
  .UiCard {
    transition-duration: 0ms;
  }
}

.UiCard__image {
  @apply absolute inset-0;
}

.UiCard__overlay {
  @apply absolute inset-0 transition duration-300 ease-out;
}

/* This is a hacky solution to solve a rendering issue only on Safari when using blur */
@supports (background: -webkit-named-image(i)) {
  .UiCard__overlay {
    @apply bg-[var(--UiCard-overlay-fallback)] [maskImage:linear-gradient(0deg,rgba(0,0,0,1)_0%,rgba(0,0,0,0)_50%)];
  }
}

@supports not (background: -webkit-named-image(i)) {
  .UiCard__overlay {
    @apply bg-[var(--UiCard-overlay)] backdrop-blur-3xl backdrop-filter [maskImage:linear-gradient(0deg,rgba(0,0,0,1)_10%,rgba(0,0,0,0)_33%)];
  }
}

:global(.theme-light) .UiCard__overlay,
:global([class^='theme-']) :global(.theme-light) .UiCard__overlay,
:global(.theme-dark) .UiCard__overlay,
:global([class^='theme-']) :global(.theme-dark) .UiCard__overlay {
  --UiCard-overlay: rgba(var(--shade-brown-950), 0.2);
  --UiCard-overlay-fallback: rgba(var(--shade-brown-950), 0.4);
}

:global(.theme-light-grey) .UiCard__overlay,
:global([class^='theme-']) :global(.theme-light-grey) .UiCard__overlay,
:global(.theme-dark-grey) .UiCard__overlay,
:global([class^='theme-']) :global(.theme-dark-grey) .UiCard__overlay {
  --UiCard-overlay: rgba(var(--shade-grey-950), 0.2);
  --UiCard-overlay-fallback: rgba(var(--shade-grey-950), 0.4);
}

.UiCard__variant__primary,
.UiCard__variant__secondary,
.UiCard__variant__tertiary,
.UiCard__variant__image {
  composes: UiCard;
}

/* Avant Arte: Default */

:global(.theme-light) .UiCard__variant__primary,
:global([class^='theme-']) :global(.theme-light) .UiCard__variant__primary {
  --UiCard-bg: rgba(var(--shade-brown-950), 1);
  --UiCard-border: transparent;
  --UiCard-text: rgba(var(--white), 1);
}

:global(.theme-light) .UiCard__variant__secondary,
:global([class^='theme-']) :global(.theme-light) .UiCard__variant__secondary {
  --UiCard-bg: rgba(var(--shade-brown-100), 1);
  --UiCard-border: transparent;
  --UiCard-text: rgba(var(--shade-brown-950), 1);
}

:global(.theme-light) .UiCard__variant__tertiary,
:global([class^='theme-']) :global(.theme-light) .UiCard__variant__tertiary {
  --UiCard-bg: rgba(var(--white), 1);
  --UiCard-border: rgba(var(--shade-brown-600), 0.2);
  --UiCard-text: rgba(var(--shade-brown-950), 1);
}

:global(.theme-light) .UiCard__variant__image,
:global([class^='theme-']) :global(.theme-light) .UiCard__variant__image {
  --UiCard-bg: rgba(var(--shade-brown-950), 1);
  --UiCard-border: transparent;
  --UiCard-text: rgba(var(--white), 1);
}

/* Avant Arte: Dark */

:global(.theme-dark) .UiCard__variant__primary,
:global([class^='theme-']) :global(.theme-dark) .UiCard__variant__primary {
  --UiCard-bg: rgba(var(--white), 1);
  --UiCard-border: transparent;
  --UiCard-text: rgba(var(--shade-brown-950), 1);
}

:global(.theme-dark) .UiCard__variant__secondary,
:global([class^='theme-']) :global(.theme-dark) .UiCard__variant__secondary {
  --UiCard-bg: rgba(var(--shade-brown-900), 1);
  --UiCard-border: transparent;
  --UiCard-text: rgba(var(--white), 1);
}

:global(.theme-dark) .UiCard__variant__tertiary,
:global([class^='theme-']) :global(.theme-dark) .UiCard__variant__tertiary {
  --UiCard-bg: rgba(var(--shade-brown-950), 1);
  --UiCard-border: rgba(var(--shade-brown-600), 0.4);
  --UiCard-text: rgba(var(--white), 1);
}

:global(.theme-dark) .UiCard__variant__image,
:global([class^='theme-']) :global(.theme-dark) .UiCard__variant__image {
  --UiCard-bg: rgba(var(--shade-brown-900), 1);
  --UiCard-border: transparent;
  --UiCard-text: rgba(var(--white), 1);
}

/* Avant Arte: Light grey */

:global(.theme-light-grey) .UiCard__variant__primary,
:global([class^='theme-'])
  :global(.theme-light-grey)
  .UiCard__variant__primary {
  --UiCard-bg: rgba(var(--shade-grey-950), 1);
  --UiCard-border: transparent;
  --UiCard-text: rgba(var(--white), 1);
}

:global(.theme-light-grey) .UiCard__variant__secondary,
:global([class^='theme-'])
  :global(.theme-light-grey)
  .UiCard__variant__secondary {
  --UiCard-bg: rgba(var(--shade-grey-100), 1);
  --UiCard-border: transparent;
  --UiCard-text: rgba(var(--shade-grey-950), 1);
}

:global(.theme-light-grey) .UiCard__variant__tertiary,
:global([class^='theme-'])
  :global(.theme-light-grey)
  .UiCard__variant__tertiary {
  --UiCard-bg: rgba(var(--white), 1);
  --UiCard-border: rgba(var(--shade-grey-600), 0.2);
  --UiCard-text: rgba(var(--shade-grey-950), 1);
}

:global(.theme-light-grey) .UiCard__variant__image,
:global([class^='theme-']) :global(.theme-light-grey) .UiCard__variant__image {
  --UiCard-bg: rgba(var(--shade-grey-950), 1);
  --UiCard-border: transparent;
  --UiCard-text: rgba(var(--white), 1);
}

/* Avant Arte: Dark grey */

:global(.theme-dark-grey) .UiCard__variant__primary,
:global([class^='theme-']) :global(.theme-dark-grey) .UiCard__variant__primary {
  --UiCard-bg: rgba(var(--white), 1);
  --UiCard-border: transparent;
  --UiCard-text: rgba(var(--shade-grey-950), 1);
}

:global(.theme-dark-grey) .UiCard__variant__secondary,
:global([class^='theme-'])
  :global(.theme-dark-grey)
  .UiCard__variant__secondary {
  --UiCard-bg: rgba(var(--shade-grey-900), 1);
  --UiCard-border: transparent;
  --UiCard-text: rgba(var(--white), 1);
}

:global(.theme-dark-grey) .UiCard__variant__tertiary,
:global([class^='theme-'])
  :global(.theme-dark-grey)
  .UiCard__variant__tertiary {
  --UiCard-bg: rgba(var(--shade-grey-950), 1);
  --UiCard-border: rgba(var(--shade-grey-600), 0.4);
  --UiCard-text: rgba(var(--white), 1);
}

:global(.theme-dark-grey) .UiCard__variant__image,
:global([class^='theme-']) :global(.theme-dark-grey) .UiCard__variant__image {
  --UiCard-bg: rgba(var(--shade-grey-900), 1);
  --UiCard-border: transparent;
  --UiCard-text: rgba(var(--white), 1);
}
