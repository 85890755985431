.m-price {
  display: flex !important;
  justify-content: center;
  flex-wrap: wrap;
  gap: 0.75rem 0.5rem;
  color: rgba(139, 121, 101, 1);
}
.m-price-bid-status {
  display: none !important;
}
.m-price-offers {
  flex-basis: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 0.75rem;

  > div {
    width: 100%;
    justify-content: center;
    display: flex;
    gap: 0.5rem;
  }
}

/** Price widget under product title **/
.product-hero {
  .manifold {
    .m-price {
      grid-template-columns: 1fr;
      color: rgb(139 121 101);
      font-size: 1.25rem;
      line-height: 1.5rem;
      letter-spacing: 0;
      @media (min-width: 768px) {
        font-size: 1.875rem;
        line-height: 2.25rem;
        letter-spacing: 0;
      }
    }
    .m-price-label {
      display: none;
    }
    .m-price-offers {
      display: none;
    }
  }
  .floor-price {
    .manifold {
      .m-price {
        .m-price-offers {
          display: block;
        }
        .m-price-label,
        .m-price-data {
          display: none;
        }
        .m-price-offer-floor {
          .m-price-data {
            display: block;
          }
        }
      }
    }
  }
}
