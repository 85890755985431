.UiDropdown {
  @apply absolute w-48 divide-y divide-[var(--UiDropdown-border)] rounded-md border border-[var(--UiDropdown-border)] bg-[var(--UiDropdown-bg)] shadow-lg shadow-divider/10;
}

.UiDropdown__group {
  @apply p-1;
}

.UiDropdown__group__item {
  @apply flex w-full gap-3 rounded-sm px-3 py-2 text-left transition duration-300 ease-out;
  @apply text-[var(--UiDropdown-text)] hover:bg-[var(--UiDropdown-bg-hover)];
}

.UiDropdown__group__item__default,
.UiDropdown__group__item__danger {
  composes: UiDropdown__group__item;
}

@media (prefers-reduced-motion) {
  .UiDropdown__group__item {
    transition-duration: 0ms;
  }
}

/* Avant Arte: Default */

:global(.theme-light) .UiDropdown,
:global([class^='theme-']) :global(.theme-light) .UiDropdown {
  --UiDropdown-bg: rgba(var(--white), 1);
  --UiDropdown-border: rgba(var(--shade-brown-600), 0.2);
}

:global(.theme-light) .UiDropdown__group__item__default,
:global([class^='theme-'])
  :global(.theme-light)
  .UiDropdown__group__item__default {
  --UiDropdown-bg-hover: rgba(var(--shade-brown-100), 1);
  --UiDropdown-text: rgba(var(--shade-brown-950), 1);
}

:global(.theme-light) .UiDropdown__group__item__danger,
:global([class^='theme-'])
  :global(.theme-light)
  .UiDropdown__group__item__danger {
  --UiDropdown-bg-hover: rgba(var(--shade-red-100), 1);
  --UiDropdown-text: rgba(var(--shade-red-600), 1);
}

/* Avant Arte: Dark */

:global(.theme-dark) .UiDropdown,
:global([class^='theme-']) :global(.theme-dark) .UiDropdown {
  --UiDropdown-bg: rgba(var(--shade-brown-950), 1);
  --UiDropdown-border: rgba(var(--shade-brown-500), 0.4);
}

:global(.theme-dark) .UiDropdown__group__item__default,
:global([class^='theme-'])
  :global(.theme-dark)
  .UiDropdown__group__item__default {
  --UiDropdown-bg-hover: rgba(var(--shade-brown-900), 1);
  --UiDropdown-text: rgba(var(--white), 1);
}

:global(.theme-dark) .UiDropdown__group__item__danger,
:global([class^='theme-'])
  :global(.theme-dark)
  .UiDropdown__group__item__danger {
  --UiDropdown-bg-hover: rgba(var(--shade-red-900), 1);
  --UiDropdown-text: rgba(var(--shade-red-200), 1);
}

/* Avant Arte: Light grey */

:global(.theme-light-grey) .UiDropdown,
:global([class^='theme-']) :global(.theme-light-grey) .UiDropdown {
  --UiDropdown-bg: rgba(var(--white), 1);
  --UiDropdown-border: rgba(var(--shade-grey-600), 0.2);
}

:global(.theme-light-grey) .UiDropdown__group__item__default,
:global([class^='theme-'])
  :global(.theme-light-grey)
  .UiDropdown__group__item__default {
  --UiDropdown-bg-hover: rgba(var(--shade-grey-100), 1);
  --UiDropdown-text: rgba(var(--shade-grey-950), 1);
}

:global(.theme-light-grey) .UiDropdown__group__item__danger,
:global([class^='theme-'])
  :global(.theme-light-grey)
  .UiDropdown__group__item__danger {
  --UiDropdown-bg-hover: rgba(var(--shade-red-100), 1);
  --UiDropdown-text: rgba(var(--shade-red-600), 1);
}

/* Avant Arte: Dark grey */

:global(.theme-dark-grey) .UiDropdown,
:global([class^='theme-']) :global(.theme-dark-grey) .UiDropdown {
  --UiDropdown-bg: rgba(var(--shade-grey-950), 1);
  --UiDropdown-border: rgba(var(--shade-grey-500), 0.4);
}

:global(.theme-dark-grey) .UiDropdown__group__item__default,
:global([class^='theme-'])
  :global(.theme-dark-grey)
  .UiDropdown__group__item__default {
  --UiDropdown-bg-hover: rgba(var(--shade-grey-900), 1);
  --UiDropdown-text: rgba(var(--white), 1);
}

:global(.theme-dark-grey) .UiDropdown__group__item__danger,
:global([class^='theme-'])
  :global(.theme-dark-grey)
  .UiDropdown__group__item__danger {
  --UiDropdown-bg-hover: rgba(var(--shade-red-900), 1);
  --UiDropdown-text: rgba(var(--shade-red-200), 1);
}
