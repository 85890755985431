.UiAvatar {
  @apply inline-flex items-center justify-center overflow-hidden rounded-full bg-[var(--UiAvatar-bg)] text-center uppercase text-[var(--UiAvatar-text)] ring-2 ring-[var(--UiAvatar-border)];
}

/* Avant Arte: Default */

:global(.theme-light) .UiAvatar,
:global([class^='theme-']) :global(.theme-light) .UiAvatar {
  --UiAvatar-bg: rgba(var(--shade-brown-100), 1);
  --UiAvatar-text: rgba(var(--shade-brown-950), 1);
  --UiAvatar-border: rgba(var(--white), 1);
}

/* Avant Arte: Dark */

:global(.theme-dark) .UiAvatar,
:global([class^='theme-']) :global(.theme-dark) .UiAvatar {
  --UiAvatar-bg: rgba(var(--shade-brown-900), 1);
  --UiAvatar-text: rgba(var(--white), 1);
  --UiAvatar-border: rgba(var(--shade-brown-950), 1);
}

/* Avant Arte: Light grey */

:global(.theme-light-grey) .UiAvatar,
:global([class^='theme-']) :global(.theme-light-grey) .UiAvatar {
  --UiAvatar-bg: rgba(var(--shade-grey-200), 1);
  --UiAvatar-text: rgba(var(--shade-grey-950), 1);
  --UiAvatar-border: rgba(var(--white), 1);
}

/* Avant Arte: Dark grey */

:global(.theme-dark-grey) .UiAvatar,
:global([class^='theme-']) :global(.theme-dark-grey) .UiAvatar {
  --UiAvatar-bg: rgba(var(--shade-grey-800), 1);
  --UiAvatar-text: rgba(var(--white), 1);
  --UiAvatar-border: rgba(var(--shade-grey-950), 1);
}
