/* ---------- Inline select ---------- */

.UiInlineSelect {
  @apply relative flex items-center justify-center overflow-hidden rounded-sm bg-red-200 pl-1 pr-6;
  @apply bg-[var(--UiInlineSelect-bg)] text-[var(--UiInlineSelect-text)];
  @apply outline outline-2 outline-offset-2 outline-transparent transition-all duration-300 ease-out;
  @apply focus-within:outline-[var(--UiInlineSelect-outline)];
}

.UiInlineSelect__select {
  @apply w-full flex-1 appearance-none bg-red-500 bg-transparent font-sans text-sm tabular-nums antialiased outline-none;
}

.UiInlineSelect__chevron {
  @apply absolute right-0 top-0;
}

/* Avant Arte: Default */

:global(.theme-light) .UiInlineSelect,
:global([class^='theme-']) :global(.theme-light) .UiInlineSelect {
  --UiInlineSelect-bg: transparent;
  --UiInlineSelect-text: rgba(var(--shade-brown-950), 1);
  --UiInlineSelect-outline: rgba(var(--shade-brown-500), 0.5);
}

/* Avant Arte: Dark */

:global(.theme-dark) .UiInlineSelect,
:global([class^='theme-']) :global(.theme-dark) .UiInlineSelect {
  --UiInlineSelect-bg: transparent;
  --UiInlineSelect-text: rgba(var(--white), 1);
  --UiInlineSelect-outline: rgba(var(--shade-brown-500), 0.75);
}

/* Avant Arte: Light grey */

:global(.theme-light-grey) .UiInlineSelect,
:global([class^='theme-']) :global(.theme-light-grey) .UiInlineSelect {
  --UiInlineSelect-bg: transparent;
  --UiInlineSelect-text: rgba(var(--shade-grey-950), 1);
  --UiInlineSelect-outline: rgba(var(--shade-grey-400), 0.5);
}

/* Avant Arte: Dark grey */

:global(.theme-dark-grey) .UiInlineSelect,
:global([class^='theme-']) :global(.theme-dark-grey) .UiInlineSelect {
  --UiInlineSelect-bg: transparent;
  --UiInlineSelect-text: rgba(var(--white), 1);
  --UiInlineSelect-outline: rgba(var(--shade-grey-500), 0.75);
}

/* ---------- Increment Input ---------- */

.UiIncrementField {
  @apply flex min-h-11 items-center rounded-sm border border-[var(--UiIncrementField-border)] bg-[var(--UiIncrementField-bg)] px-1 text-[var(--UiIncrementField-text)];
}

.UiIncrementField__number {
  @apply block min-w-5 text-center;
}

.UiIncrementField__disabled {
  composes: UiIncrementField;
  @apply cursor-not-allowed;
}

/* Avant Arte: Default */

:global(.theme-light) .UiIncrementField,
:global([class^='theme-']) :global(.theme-light) .UiIncrementField {
  --UiIncrementField-bg: rgba(var(--white), 1);
  --UiIncrementField-border: rgba(var(--shade-brown-600), 0.2);
  --UiIncrementField-text: rgba(var(--shade-brown-950), 1);
}

:global(.theme-light) .UiIncrementField__disabled,
:global([class^='theme-']) :global(.theme-light) .UiIncrementField__disabled {
  --UiIncrementField-bg: rgba(var(--shade-brown-50), 1);
  --UiIncrementField-border: rgba(var(--shade-brown-600), 0.2);
  --UiIncrementField-text: rgba(var(--shade-brown-600), 1);
}

/* Avant Arte: Dark */

:global(.theme-dark) .UiIncrementField,
:global([class^='theme-']) :global(.theme-dark) .UiIncrementField {
  --UiIncrementField-bg: rgba(var(--shade-brown-950), 1);
  --UiIncrementField-border: rgba(var(--shade-brown-600), 0.4);
  --UiIncrementField-text: rgba(var(--white), 1);
}

:global(.theme-dark) .UiIncrementField__disabled,
:global([class^='theme-']) :global(.theme-dark) .UiIncrementField__disabled {
  --UiIncrementField-bg: rgba(var(--shade-brown-900), 0.3);
  --UiIncrementField-border: rgba(var(--shade-brown-600), 0.4);
  --UiIncrementField-text: rgba(var(--shade-brown-400), 1);
}

/* Avant Arte: Light grey */

:global(.theme-light-grey) .UiIncrementField,
:global([class^='theme-']) :global(.theme-light-grey) .UiIncrementField {
  --UiIncrementField-bg: rgba(var(--white), 1);
  --UiIncrementField-border: rgba(var(--shade-grey-600), 0.2);
  --UiIncrementField-text: rgba(var(--shade-grey-950), 1);
}

:global(.theme-light-grey) .UiIncrementField__disabled,
:global([class^='theme-'])
  :global(.theme-light-grey)
  .UiIncrementField__disabled {
  --UiIncrementField-bg: rgba(var(--shade-grey-100), 1);
  --UiIncrementField-border: rgba(var(--shade-grey-600), 0.2);
  --UiIncrementField-text: rgba(var(--shade-grey-400), 1);
}

/* Avant Arte: Dark grey */

:global(.theme-dark-grey) .UiIncrementField,
:global([class^='theme-']) :global(.theme-dark-grey) .UiIncrementField {
  --UiIncrementField-bg: rgba(var(--shade-grey-950), 1);
  --UiIncrementField-border: rgba(var(--shade-grey-600), 0.4);
  --UiIncrementField-text: rgba(var(--white), 1);
}

:global(.theme-dark-grey) .UiIncrementField__disabled,
:global([class^='theme-'])
  :global(.theme-dark-grey)
  .UiIncrementField__disabled {
  --UiIncrementField-bg: rgba(var(--shade-grey-800), 0.75);
  --UiIncrementField-border: rgba(var(--shade-grey-600), 0.4);
  --UiIncrementField-text: rgba(var(--shade-grey-400), 1);
}

/* ---------- Input, textarea and select fields ---------- */

.UiField {
  @apply flex w-full flex-col gap-y-2;
}

.UiField__label {
  @apply w-full text-xs text-[var(--UiField-text-label)];
}

.UiField__note {
  @apply w-full text-xs text-[var(--UiField-text-note)];
}

.UiField__error {
  @apply w-full text-xs text-[var(--UiField-text-danger)];
}

.UiField__field {
  @apply relative flex w-full gap-2 rounded-sm px-3;
  @apply border border-[var(--UiField-border)] bg-[var(--UiField-bg)] text-[var(--UiField-text)];
  @apply outline outline-2 outline-offset-2 outline-transparent transition-all duration-300 ease-out;
  @apply focus-within:outline-[var(--UiField-outline)];
}

.UiField__field > span {
  @apply py-3;
}

@media (prefers-reduced-motion) {
  .UiField__field {
    @apply transition-none;
  }
}

.UiField__field input,
.UiField__field select,
.UiField__field textarea {
  @apply font-sans text-sm tabular-nums antialiased;
  @apply w-full flex-1 appearance-none bg-transparent py-3 text-sm outline-none;
}

.UiField__field input[type='date'] {
  @apply h-11;
}

.UiField__field input::placeholder,
.UiField__field select::placeholder,
.UiField__field textarea::placeholder {
  @apply text-sm-mobile text-[var(--UiField-text-placeholder)] md:text-sm;
}

.UiField__field input:disabled,
.UiField__field select:disabled,
.UiField__field textarea:disabled {
  @apply cursor-not-allowed text-[var(--UiField-text-disabled)];
}

.UiField__field__disabled {
  @apply cursor-not-allowed;
}

/* Variants */

.UiField__field__error,
.UiField__field__disabled {
  composes: UiField__field;
}

/* Avant Arte: Default */

:global(.theme-light) .UiField,
:global([class^='theme-']) :global(.theme-light) .UiField {
  --UiField-text-label: rgba(var(--shade-brown-950), 1);
  --UiField-text-note: rgba(var(--shade-brown-600), 1);
  --UiField-text-danger: rgba(var(--shade-red-600), 1);
}

:global(.theme-light) .UiField__field,
:global([class^='theme-']) :global(.theme-light) .UiField__field {
  --UiField-bg: rgba(var(--white), 1);
  --UiField-border: rgba(var(--shade-brown-600), 0.2);
  --UiField-text: rgba(var(--shade-brown-950), 1);
  --UiField-text-placeholder: rgba(var(--shade-brown-600), 1);
  --UiField-text-disabled: rgba(var(--shade-brown-600), 1);
  --UiField-outline: rgba(var(--shade-brown-500), 0.5);
}

:global(.theme-light) .UiField__field__error,
:global([class^='theme-']) :global(.theme-light) .UiField__field__error {
  --UiField-bg: rgba(var(--white), 1);
  --UiField-border: rgba(var(--shade-red-600), 0.2);
  --UiField-text: rgba(var(--shade-red-950), 1);
  --UiField-text-placeholder: rgba(var(--shade-red-600), 1);
  --UiField-text-disabled: rgba(var(--shade-red-600), 1);
  --UiField-outline: rgba(var(--shade-red-500), 0.5);
}

:global(.theme-light) .UiField__field__disabled,
:global([class^='theme-']) :global(.theme-light) .UiField__field__disabled {
  --UiField-bg: rgba(var(--shade-brown-50), 1);
  --UiField-border: rgba(var(--shade-brown-600), 0.2);
  --UiField-text: rgba(var(--shade-brown-600), 1);
  --UiField-text-placeholder: rgba(var(--shade-brown-600), 1);
  --UiField-text-disabled: rgba(var(--shade-brown-600), 1);
  --UiField-outline: transparent;
}

/* Avant Arte: Dark */

:global(.theme-dark) .UiField,
:global([class^='theme-']) :global(.theme-dark) .UiField {
  --UiField-text-label: rgba(var(--white), 1);
  --UiField-text-note: rgba(var(--shade-brown-400), 1);
  --UiField-text-danger: rgba(var(--shade-red-400), 1);
}

:global(.theme-dark) .UiField__field,
:global([class^='theme-']) :global(.theme-dark) .UiField__field {
  --UiField-bg: rgba(var(--shade-brown-950), 1);
  --UiField-border: rgba(var(--shade-brown-600), 0.4);
  --UiField-text: rgba(var(--white), 1);
  --UiField-text-placeholder: rgba(var(--shade-brown-400), 1);
  --UiField-text-disabled: rgba(var(--shade-brown-400), 1);
  --UiField-outline: rgba(var(--shade-brown-500), 0.75);
}

:global(.theme-dark) .UiField__field__error,
:global([class^='theme-']) :global(.theme-dark) .UiField__field__error {
  --UiField-bg: rgba(var(--shade-brown-950), 1);
  --UiField-border: rgba(var(--shade-red-600), 0.4);
  --UiField-text: rgba(var(--shade-red-100), 1);
  --UiField-text-placeholder: rgba(var(--shade-red-400), 1);
  --UiField-text-disabled: rgba(var(--shade-red-400), 1);
  --UiField-outline: rgba(var(--shade-red-500), 0.75);
}

:global(.theme-dark) .UiField__field__disabled,
:global([class^='theme-']) :global(.theme-dark) .UiField__field__disabled {
  --UiField-bg: rgba(var(--shade-brown-900), 0.3);
  --UiField-border: rgba(var(--shade-brown-600), 0.4);
  --UiField-text: rgba(var(--white), 1);
  --UiField-text-placeholder: rgba(var(--shade-brown-500), 1);
  --UiField-text-disabled: rgba(var(--shade-brown-500), 1);
  --UiField-outline: transparent;
}

/* Avant Arte: Light grey */

:global(.theme-light-grey) .UiField,
:global([class^='theme-']) :global(.theme-light-grey) .UiField {
  --UiField-text-label: rgba(var(--shade-grey-950), 1);
  --UiField-text-note: rgba(var(--shade-grey-500), 1);
  --UiField-text-danger: rgba(var(--shade-red-600), 1);
}

:global(.theme-light-grey) .UiField__field,
:global([class^='theme-']) :global(.theme-light-grey) .UiField__field {
  --UiField-bg: rgba(var(--white), 1);
  --UiField-border: rgba(var(--shade-grey-600), 0.2);
  --UiField-text: rgba(var(--shade-grey-950), 1);
  --UiField-text-placeholder: rgba(var(--shade-grey-500), 1);
  --UiField-text-disabled: rgba(var(--shade-grey-500), 1);
  --UiField-outline: rgba(var(--shade-grey-400), 0.5);
}

:global(.theme-light-grey) .UiField__field__error,
:global([class^='theme-']) :global(.theme-light-grey) .UiField__field__error {
  --UiField-bg: rgba(var(--white), 1);
  --UiField-border: rgba(var(--shade-red-600), 0.2);
  --UiField-text: rgba(var(--shade-red-950), 1);
  --UiField-text-placeholder: rgba(var(--shade-red-500), 1);
  --UiField-text-disabled: rgba(var(--shade-red-500), 1);
  --UiField-outline: rgba(var(--shade-red-400), 0.5);
}

:global(.theme-light-grey) .UiField__field__disabled,
:global([class^='theme-'])
  :global(.theme-light-grey)
  .UiField__field__disabled {
  --UiField-bg: rgba(var(--shade-grey-100), 1);
  --UiField-border: rgba(var(--shade-grey-600), 0.2);
  --UiField-text: rgba(var(--shade-grey-400), 1);
  --UiField-text-placeholder: rgba(var(--shade-grey-500), 1);
  --UiField-text-disabled: rgba(var(--shade-grey-500), 1);
  --UiField-outline: transparent;
}

/* Avant Arte: Dark grey */

:global(.theme-dark-grey) .UiField,
:global([class^='theme-']) :global(.theme-dark-grey) .UiField {
  --UiField-text-label: rgba(var(--white), 1);
  --UiField-text-note: rgba(var(--shade-grey-400), 1);
  --UiField-text-danger: rgba(var(--shade-red-400), 1);
}

:global(.theme-dark-grey) .UiField__field,
:global([class^='theme-']) :global(.theme-dark-grey) .UiField__field {
  --UiField-bg: rgba(var(--shade-grey-950), 1);
  --UiField-border: rgba(var(--shade-grey-600), 0.4);
  --UiField-text: rgba(var(--white), 1);
  --UiField-text-placeholder: rgba(var(--shade-grey-400), 1);
  --UiField-text-disabled: rgba(var(--shade-grey-400), 1);
  --UiField-outline: rgba(var(--shade-grey-500), 0.75);
}

:global(.theme-dark-grey) .UiField__field__error,
:global([class^='theme-']) :global(.theme-dark-grey) .UiField__field__error {
  --UiField-bg: rgba(var(--shade-grey-950), 1);
  --UiField-border: rgba(var(--shade-red-600), 0.4);
  --UiField-text: rgba(var(--shade-red-100), 1);
  --UiField-text-placeholder: rgba(var(--shade-red-400), 1);
  --UiField-text-disabled: rgba(var(--shade-red-400), 1);
  --UiField-outline: rgba(var(--shade-red-500), 0.75);
}

:global(.theme-dark-grey) .UiField__field__disabled,
:global([class^='theme-']) :global(.theme-dark-grey) .UiField__field__disabled {
  --UiField-bg: rgba(var(--shade-grey-800), 0.75);
  --UiField-border: rgba(var(--shade-grey-600), 0.4);
  --UiField-text: rgba(var(--white), 1);
  --UiField-text-placeholder: rgba(var(--shade-grey-400), 1);
  --UiField-text-disabled: rgba(var(--shade-grey-400), 1);
  --UiField-outline: transparent;
}
