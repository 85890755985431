/* ---------- Spinner ---------- */

.UiSpinner {
  @apply origin-center animate-spin;
}

/* ---------- Pulsing dot ---------- */

.UiPulsingDot {
  @apply relative text-[var(--UiPulsingDot-text)];
}

.UiPulsingDot__animate {
  @apply absolute left-0 top-0 animate-ping;
}

@media (prefers-reduced-motion) {
  .UiPulsingDot__animate {
    animation-duration: 0ms;
  }
}

/* Avant Arte: Default */

:global(.theme-light) .UiPulsingDot,
:global([class^='theme-']) :global(.theme-light) .UiPulsingDot {
  --UiPulsingDot-text: rgba(var(--shade-orange-500), 1);
}

/* Avant Arte: Dark */

:global(.theme-dark) .UiPulsingDot,
:global([class^='theme-']) :global(.theme-dark) .UiPulsingDot {
  --UiPulsingDot-text: rgba(var(--shade-orange-400), 1);
}

/* Avant Arte: Light grey */

:global(.theme-light-grey) .UiPulsingDot,
:global([class^='theme-']) :global(.theme-light-grey) .UiPulsingDot {
  --UiPulsingDot-text: rgba(var(--shade-orange-500), 1);
}

/* Avant Arte: Dark grey */

:global(.theme-dark-grey) .UiPulsingDot,
:global([class^='theme-']) :global(.theme-dark-grey) .UiPulsingDot {
  --UiPulsingDot-text: rgba(var(--shade-orange-400), 1);
}
