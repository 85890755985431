html.is-livestream,
html.is-livestream body {
  @apply w-screen overflow-hidden;
  height: -webkit-fill-available;
  position: fixed;
}

.str-chat,
.str-chat__container,
.str-chat__main-panel {
  @apply relative h-full w-full bg-transparent;
}

.str-chat__container {
  @apply flex flex-col justify-end;
}

.str-chat__virtual-list,
.str-chat__list-notifications,
.str-chat__custom-input-wrapper {
  @apply px-4;
}

.str-chat__custom-message {
  @apply flex w-full flex-col items-start pb-1 tabular-nums md:pb-2;
}

.str-chat__custom-message-text {
  @apply whitespace-pre-line break-words;
  word-break: break-word;
}

.str-chat__custom-message-error {
  @apply flex items-center pt-0.5;
}

.str-chat__custom-message-error svg {
  @apply h-3 w-3 lg:h-4 lg:w-4;
}

.str-chat__custom-input-wrapper {
  @apply relative text-sm placeholder:text-sm-mobile md:placeholder:text-sm;
}

.str-chat__textarea {
  @apply z-10 max-h-14;
}

.str-chat__textarea textarea {
  @apply m-0 w-full resize-none rounded-sm px-4 pb-4 pr-14 pt-4 leading-6;
}

.str-chat__submit-button {
  @apply right-8 top-3 h-8 w-8 rounded-full;
  @apply md:right-6 md:top-2 md:h-9 md:w-9;
  @apply absolute z-20 flex items-center justify-center;
}

.str-chat__list-notifications {
  @apply relative w-full;
}

.str-chat__notification {
  @apply w-full truncate px-4 py-2 lg:py-3;
}

.str-chat__notification.notification-error,
.str-chat__notification.notification-success {
  @apply bg-neutral-1;
}

.str-chat__loading {
  @apply flex w-full items-center justify-center;
  @apply h-14 px-4 py-4 text-white;
}

.str-chat__message-notification {
  @apply ml-[50%] hidden translate-x-[-50%] bg-neutral-6 text-white lg:block;
  @apply absolute bottom-2 whitespace-nowrap rounded-full px-3 py-2;
}

.str-chat__badge {
  @apply fill-verified-4;
}

.str-chat__glass .str-chat__badge {
  @apply fill-white;
}

/* glass theme */
.str-chat__glass .str-chat__virtual-list,
.str-chat__glass .str-chat__message-list-scroll {
  @apply min-h-48;
  mask-image: linear-gradient(
    color-stop(0, rgba(0, 0, 0, 0)),
    color-stop(0.4, rgba(0, 0, 0, 1)),
    color-stop(1, rgba(0, 0, 0, 1))
  );
  -webkit-mask-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0, rgba(0, 0, 0, 0)),
    color-stop(0.4, rgba(0, 0, 0, 1)),
    color-stop(1, rgba(0, 0, 0, 1))
  );
}

.str-chat__glass .str-chat__custom-message {
  @apply tabular-nums;
}

.str-chat__glass .str-chat__custom-message-text {
  @apply text-white;
}

.str-chat__glass .str-chat__custom-message-error {
  @apply text-neutral-3;
}

.str-chat__glass .str-chat__custom-message-error svg path {
  @apply fill-neutral-2;
}

.str-chat__glass .str-chat__textarea {
  box-shadow: 0px 0px 104px rgba(43, 32, 17, 0.3);
}

.str-chat__glass .str-chat__textarea textarea {
  @apply bg-white/20 text-white backdrop-blur-lg backdrop-filter;
}

.str-chat__glass .str-chat__textarea textarea::placeholder {
  @apply text-white/60;
}

.str-chat__glass .str-chat__textarea textarea:focus {
  @apply outline-none;
}

.str-chat__glass .str-chat__submit-button {
  @apply bg-white;
}

.str-chat__glass .str-chat__submit-button path {
  @apply fill-neutral-6;
}

/* neutral theme */
.str-chat__neutral.str-chat {
  @apply bg-white;
}

.str-chat__neutral .str-chat__virtual-list,
.str-chat__neutral .str-chat__message-list-scroll {
  @apply min-h-[calc(100%-60px)];
}

.str-chat__neutral.str-chat__offline .str-chat__virtual-list,
.str-chat__neutral.str-chat__offline .str-chat__message-list-scroll {
  @apply min-h-[calc(100%-102px)];
}

.str-chat__neutral .str-chat__custom-message-text {
  @apply bg-white/80;
}

.str-chat__neutral .str-chat__custom-message-error {
  @apply text-sm text-neutral-4;
}

.str-chat__neutral .str-chat__custom-message-error svg path {
  @apply fill-neutral-4;
}

.str-chat__neutral .str-chat__textarea textarea {
  @apply bg-neutral-1;
}

.str-chat__neutral .str-chat__textarea textarea::placeholder {
  @apply text-neutral-4;
}

.str-chat__neutral .str-chat__textarea textarea:focus {
  @apply shadow-border outline-none;
}

.str-chat__neutral .str-chat__submit-button {
  @apply bg-neutral-6;
}

.str-chat__neutral .str-chat__submit-button path {
  @apply fill-white;
}
